import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import ReactGA from 'react-ga'
import Button from '../components/Button'
import HeaderLogo from '../components/HeaderLogo'
import tree from '../trees/judgingLabels'
import { ReactComponent as Arrow } from '../assets/img/arrow.svg'
import { useHistory } from 'react-router-dom'
import { theme } from '../globalStyle'
import ShareModal from '../components/ShareModal'

const MenuContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  @media ${(props) => props.theme.device.mobile} {
    flex-direction: column;
    padding-top: 100px;
    height: auto;
  }
  ${(props) => css`
    background-color: ${props.outcome
      ? props.theme.colors.gableGreen
      : props.theme.colors.islandSpice};
  `}
`
const MenuSectionContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px;

  &:first-of-type {
    border-right: 1px solid
      ${(props) =>
        props.outcome
          ? props.theme.colors.harp
          : props.theme.colors.gableGreen};
  }

  @media ${(props) => props.theme.device.mobile} {
    width: auto;
    padding: 0;
  }
`
const MenuSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  padding: 20px 0;
  width: 100%;
  padding: 30px;
  ${(props) => css`
    justify-content: ${props.left ? 'space-between' : 'space-around'};
    max-height: ${props.left ? '70vh' : 'auto'};
    overflow-y: ${props.left ? 'auto' : 'none'};
  `}

  @media ${(props) => props.theme.device.mobile} {
    width: 100%;
    padding: 20px 0;
    max-height: none;
  }
`
const MenuSection = ({ children, ...props }) => (
  <MenuSectionContainer {...props}>
    <MenuSectionContent {...props}>{children}</MenuSectionContent>
  </MenuSectionContainer>
)
const LogoContainer = styled.div`
  position: fixed;
  top: 20px;
  left: 20px;

  @media ${(props) => props.theme.device.mobile} {
    position: absolute;
  }
`

const NarrationText = styled.p`
  margin: 10px 0;
  animation: fadeUp ease ${(props) => (props.i ? (props.i + 1) * 0.3 : 0.3)}s;

  ${(props) => css`
    font-size: ${props.large ? '1.4em' : '1em'};
    font-family: ${props.theme.fonts.nueHaas65};
    color: ${(props) =>
      props.outcome ? props.theme.colors.harp : props.theme.colors.gableGreen};
  `}
`
const BoldText = styled(NarrationText)`
  font-family: ${(props) => props.theme.fonts.nueHaas75};
`

const Caption = styled.div`
  margin: 10px 0 20px;
`
const CaptionText = styled.p`
  font-size: 0.8em;
  font-family: ${(props) => props.theme.fonts.nueHaas65};
  margin: 0;
  color: ${(props) => props.theme.colors.stromboli};
`

const HeaderText = styled.h1`
  font-family: ${(props) => props.theme.fonts.dreamOrphans};

  ${(props) => css`
    color: ${(props) =>
      props.outcome ? props.theme.colors.harp : props.theme.colors.gableGreen};
  `}
`
const BodyText = styled.p`
  font-family: ${(props) => props.theme.fonts.nueHaas75};
  color: ${(props) => props.theme.colors.gableGreen};

  ${(props) => css`
    color: ${(props) =>
      props.outcome ? props.theme.colors.harp : props.theme.colors.gableGreen};
  `}
`
const NextButton = styled(Arrow)`
  width: 70px;
  transform: rotate(90deg);
  position: fixed;
  bottom: 40px;
  right: 40px;
  cursor: pointer;

  @media ${(props) => props.theme.device.mobile} {
    display: none;
  }
`

const ChoiceButton = styled(Button)`
  width: 100%;
  margin: 20px 0;
  text-align: left;
  padding: 20px 40px;

  animation: fadeLeft ease ${(props) => (props.i ? (props.i + 1) * 0.3 : 0.3)}s;

  @keyframes fadeLeft {
    0% {
      opacity: 0;
      transform: translateX(50px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @media ${(props) => props.theme.device.mobile} {
    font-size: 1.2em;
    margin: 10px 0;
  }
  ${(props) =>
    css`
      border: 1px solid
        ${props.outcome ? props.theme.colors.harp : props.theme.colors.zambezi};
      color: ${props.outcome
        ? props.theme.colors.harp
        : props.theme.colors.gableGreen};
      &:hover {
        color: ${props.outcome
          ? props.theme.colors.gableGreen
          : props.theme.colors.harp};
        background-color: ${props.outcome
          ? props.theme.colors.harp
          : props.theme.colors.gableGreen};
      }
    `}
`

const DonateButton = styled(Button)`
  width: 240px;
  padding: 10px 20px;
  position: fixed;
  bottom: 20px;
  right: 51vw;
  border: 1px solid ${(props) => props.theme.colors.harp};
  color: ${(props) => props.theme.colors.harp};

  &:hover {
    color: ${(props) => props.theme.colors.gableGreen};
  }

  @media ${(props) => props.theme.device.mobile} {
    display: none;
  }
`

const JudgingLabels = ({ setHamburgerColor }) => {
  const [node, setNode] = useState(tree.find((node) => node.id === 'start'))
  const history = useHistory()
  const [shareModalOpen, setShareModalOpen] = useState(false)

  useEffect(() => {
    setHamburgerColor(theme.colors.gableGreen)
  }, [setHamburgerColor])

  const setNodeFromId = (id) => {
    window.scrollTo({ top: 0 })
    const newNode = tree.find((node) => node.id === id)
    if (!newNode) return
    setNode(newNode)
    setHamburgerColor(
      newNode.outcome ? theme.colors.harp : theme.colors.gableGreen,
    )
    if (newNode.outcome) {
      ReactGA.event({
        category: 'Completion of Experience (SAW21)',
        label: 'SAW 21 - Completed experience (Labels)',
        action: 'Complete experience (Labels)',
      })
    }
  }

  const handleReplay = () => {
    ReactGA.event({
      category: 'Button (SAW21)',
      label: 'SAW 21 - Replayed Labels',
      action: 'Replay (Labels)',
    })
    setNodeFromId('start')
  }

  const handleDonate = () => {
    ReactGA.event({
      category: 'Button (SAW21)',
      action: 'Click',
      label: 'SAW 21 - Donation (Menu)',
    })
    history.push('/donation')
  }

  const RightSection = () => (
    <>
      {node.rightHeaderText && (
        <HeaderText outcome={node.outcome}>{node.rightHeaderText}</HeaderText>
      )}
      {node.image && node.choices ? (
        Array.isArray(node.rightBodyText) ? (
          node.rightBodyText.map((text, i) =>
            text[0] === '!' ? (
              <BoldText i={i} key={text}>
                {text.slice(1)}
              </BoldText>
            ) : (
              <NarrationText outcome={node.outcome} i={i} key={text}>
                {text}
              </NarrationText>
            ),
          )
        ) : (
          <NarrationText outcome={node.outcome}>
            {node.rightBodyText}
          </NarrationText>
        )
      ) : null}
      {node.choices ? (
        node.choices.map((choice, i) => (
          <ChoiceButton
            ghost
            arrow
            i={i}
            key={`${choice.id}-${i}`}
            onClick={() => setNodeFromId(choice.to)}
          >
            {choice.text}
          </ChoiceButton>
        ))
      ) : (
        <>
          {Array.isArray(node.rightBodyText) ? (
            node.rightBodyText.map((text) => (
              <BodyText outcome={node.outcome} key={text}>
                {text}
              </BodyText>
            ))
          ) : (
            <BodyText outcome={node.outcome}>{node.rightBodyText}</BodyText>
          )}
          <NextButton onClick={() => setNodeFromId(node.next)} />
        </>
      )}
      {node.outcome && (
        <>
          <ChoiceButton
            lightArrow
            ghost
            outcome
            arrow
            i={0}
            onClick={handleReplay}
          >
            Replay from the beginning.
          </ChoiceButton>
          <ChoiceButton
            i={1}
            ghost
            lightArrow
            outcome
            arrow
            onClick={() => history.push('/select-debate')}
          >
            Play the other experience, 'Passion Made Impossible'.
          </ChoiceButton>
        </>
      )}
    </>
  )

  const StyledImage = node.image
    ? styled(node.image)`
        max-height: 50vh;
        max-width: 100%;
        margin: 0 auto;
        border: 1px solid black;
        padding: 10px;

        @media ${(props) => props.theme.device.mobile} {
          width: 100vw;
          padding: 0;
          border: none;
        }
      `
    : styled.p``

  const ShareButton = styled(Button)`
    width: 400px;
    position: fixed;
    padding: 10px 20px;
    bottom: 20px;
    left: 1vw;
    border: 1px solid ${(props) => props.theme.colors.harp};
    color: ${(props) => props.theme.colors.harp};

    &:hover {
      color: ${(props) => props.theme.colors.gableGreen};
    }
    @media ${(props) => props.theme.device.mobile} {
      display: none;
    }
  `

  const MobileShareButton = styled(Button)`
    width: 300px;
    padding: 10px;
    margin-top: 20px;
    border: 1px solid ${(props) => props.theme.colors.harp};
    color: ${(props) => props.theme.colors.harp};
    display: none;

    &:hover {
      color: ${(props) => props.theme.colors.gableGreen};
    }
    @media ${(props) => props.theme.device.mobile} {
      display: block;
    }
  `

  const handleShare = () => {
    setShareModalOpen(true)
  }

  const LeftSection = () => (
    <>
      {node.image && <StyledImage />}
      {Array.isArray(node.imageCaption) && (
        <Caption>
          {node.imageCaption.map((line) => (
            <CaptionText>{line}</CaptionText>
          ))}
        </Caption>
      )}
      {node.outcome && (
        <MobileShareButton ghost onClick={handleShare}>
          Share Your Results
        </MobileShareButton>
      )}
      {node.headerText && (
        <HeaderText outcome={node.outcome}>{node.headerText}</HeaderText>
      )}
      {Array.isArray(node.bodyText) ? (
        node.bodyText.map((text, i) =>
          text[0] === '!' ? (
            <BoldText i={i} key={text}>
              {text.slice(1)}
            </BoldText>
          ) : (
            <NarrationText
              outcome={node.outcome}
              large={!node.image}
              i={i}
              key={text}
            >
              {text}
            </NarrationText>
          ),
        )
      ) : (
        <NarrationText outcome={node.outcome} large={!node.image}>
          {node.bodyText}
        </NarrationText>
      )}
      {node.outcome && (
        <>
          <ShareButton arrow lightArrow ghost onClick={handleShare}>
            Share Your Results
          </ShareButton>
          <DonateButton arrow lightArrow ghost onClick={handleDonate}>
            Donate
          </DonateButton>
        </>
      )}
    </>
  )

  return (
    <MenuContainer outcome={node.outcome}>
      <MenuSection left outcome={node.outcome}>
        <LeftSection />
      </MenuSection>
      <MenuSection>
        <RightSection />
      </MenuSection>
      <LogoContainer>
        <HeaderLogo green={!node.outcome} />
      </LogoContainer>
      <ShareModal
        node={node}
        setShareModalOpen={setShareModalOpen}
        shareModalOpen={shareModalOpen}
        shareHeader={node.shareHeader}
        shareBody={node.shareBody}
      />
    </MenuContainer>
  )
}
export default JudgingLabels
