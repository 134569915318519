import { createGlobalStyle } from 'styled-components'
import dreamOrphansBd from './assets/fonts/dream-orphans-bd.ttf'
import nHaas55 from './assets/fonts/NHaasGroteskTXPro-55Rg.ttf'
import nHaas65 from './assets/fonts/NHaasGroteskTXPro-65Md.ttf'
import nHaas75 from './assets/fonts/NHaasGroteskTXPro-75Bd.ttf'

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
}

export const theme = {
  colors: {
    harp: '#CCCFC5',
    islandSpice: '#F6EEE2',
    gableGreen: '#2A423C',
    stromboli: '#49635A',
    zambezi: '#606060',
  },
  fonts: {
    dreamOrphans: 'dreamOrphans',
    nueHaas55: 'nueHass55',
    nueHaas65: 'nueHass65',
    nueHaas75: 'nueHass75',
  },
  device: {
    mobile: `(max-width: ${size.tablet})`,
  },
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: dreamOrphans;
    src: url(${dreamOrphansBd}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: nueHass55;
    src: url(${nHaas55}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: nueHass65;
    src: url(${nHaas65}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: nueHass75;
    src: url(${nHaas75}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  body::-webkit-scrollbar { 
    display: none;
  }

`

export default GlobalStyle
