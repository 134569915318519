import { useState } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import Hamburger from 'hamburger-react'
import { theme } from '../globalStyle'
import HeaderLogo from '../components/HeaderLogo'
import nav from '../assets/img/nav.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const HamburgerContainer = styled.div`
  position: fixed;
  z-index: 1;
  top: 20px;
  right: 20px;

  @media ${(props) => props.theme.device.mobile} {
    position: absolute;
  }
`
const MenuContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background-color: ${(props) => props.theme.colors.gableGreen};
  display: flex;
  flex-direction: row;
  animation: fadeIn ease 0.3s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media ${(props) => props.theme.device.mobile} {
    flex-direction: column;
  }
`
const MenuSectionContainer = styled.div`
  width: 50%;
  display: flex;

  &:first-of-type {
    border-right: 1px solid white;
  }

  @media ${(props) => props.theme.device.mobile} {
    width: 100%;
    border: none;
    margin: 0;
  }

  ${(props) =>
    props.left &&
    css`
      margin: 0 20px;
    `}
`
const MenuSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px 0;
  min-width: 80%;
  ${(props) => css`
    justify-content: ${props.left ? 'space-between' : 'space-around'};
  `}
`
const MenuSection = ({ children, ...props }) => (
  <MenuSectionContainer {...props}>
    <MenuSectionContent {...props}>{children}</MenuSectionContent>
  </MenuSectionContainer>
)

const Splash = styled.img`
  width: 80%;
  margin: 0 auto;
  @media ${(props) => props.theme.device.mobile} {
    width: 300px;
    height: 200px;
    margin: 20px auto;
  }
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
`
const Socials = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: fixed;
  bottom: 20px;
  right: 20px;
`
const MenuLink = styled(Link)`
  text-decoration: none;
  font-family: ${(props) => props.theme.fonts.dreamOrphans};
  font-size: 5em;
  color: white;
  transition: color 0.5s;
  margin: 10px 0;
  &:hover {
    color: ${(props) => props.theme.colors.harp};
  }

  ${(props) => css`
    animation: linkFadeIn ease ${0.6 + props.i * 0.4}s;
  `}
  @keyframes linkFadeIn {
    0% {
      transform: translateX(100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @media ${(props) => props.theme.device.mobile} {
    font-size: 2em;
    margin: 5px;
  }
`

const OHLink = styled.a`
  font-family: ${(props) => props.theme.fonts.nueHaas75};
  text-decoration: none;
  font-size: 1em;
  color: white;
`

const SocialBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  width: 80px;
  height: 80px;
  margin: 0 10px;
  background: ${(props) => props.theme.colors.islandSpice};
  cursor: pointer;
  @media ${(props) => props.theme.device.mobile} {
    width: 50px;
    height: 50px;
  }
`

const linksArr = [
  { children: 'About', to: '/about' },
  { children: 'Experiences', to: '/' },
  { children: 'Donate', to: '/donation' },
  { children: 'Credits', to: '/credits' },
]

const Menu = ({ onClickLink }) => (
  <MenuContainer>
    <MenuSection left>
      <HeaderLogo />
      <Splash src={nav} />
      <OHLink target="_blank" href="http://ohopenhouse.org/" rel="noreferrer">
        ohopenhouse.org
      </OHLink>
    </MenuSection>
    <MenuSection>
      <Links>
        {linksArr.map((props, i) => (
          <MenuLink key={i} {...props} onClick={onClickLink} i={i} />
        ))}
      </Links>
      <Socials>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.facebook.com/ohopenhouse"
        >
          <SocialBtn>
            <FontAwesomeIcon
              icon={['fab', 'facebook-f']}
              size="2x"
              color={theme.colors.gableGreen}
            />
          </SocialBtn>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/ohopenhouse"
        >
          <SocialBtn>
            <FontAwesomeIcon
              icon={['fab', 'instagram']}
              size="2x"
              color={theme.colors.gableGreen}
            />
          </SocialBtn>
        </a>
      </Socials>
    </MenuSection>
  </MenuContainer>
)

const Navigation = ({ hamburgerColor }) => {
  const [open, setOpen] = useState(false)
  const onClick = () => setOpen(false)

  return (
    <>
      {open && <Menu onClickLink={onClick} />}
      <HamburgerContainer>
        <Hamburger
          distance="sm"
          size={56}
          toggled={open}
          toggle={setOpen}
          color={open ? theme.colors.harp : hamburgerColor}
        />
      </HamburgerContainer>
    </>
  )
}

export default Navigation
