import { useState, useEffect, useCallback } from 'react'
import styled, { css } from 'styled-components'
import Button from '../components/Button'
import { ReactComponent as Logo } from '../assets/img/fthath-logo.svg'
import { ReactComponent as Arrow } from '../assets/img/arrow.svg'
import { Link } from 'react-router-dom'

const TitleSection = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
`

const StyledLogo = styled(Logo)`
  width: 220px;
`

const StyledArrow = styled(Arrow)`
  position: fixed;
  bottom: 20px;
  right: 10px;
  cursor: pointer;
  height: 50px;
  transition: 0.3s ease-in-out;
  transform: rotate(180deg);

  ${(props) =>
    !props.show &&
    css`
      display: none;
    `}
`

const StyledButton = styled(Button)`
  width: 300px;
  justify-content: center;
  margin: 20px auto;
  padding: 10px;
`

const Subheader = styled.h2`
  height: auto;
  font-size: 1.2em;
  margin: 0;
  font-family: ${(props) => props.theme.fonts.dreamOrphans};
  pointer-events: none;
`
const LeftHeader = styled(Subheader)`
  color: ${(props) => props.theme.colors.stromboli};
  width: 250px;
  animation: leftHeader ease 1s;
  transform: translateX(-30px) translateY(45%);

  @keyframes leftHeader {
    0% {
      opacity: 0;
      transform: translateX(-30px) translateY(35%);
    }
    100% {
      opacity: 1;
      transform: translateX(-30px) translateY(45%);
    }
  }

  @media ${(props) => props.theme.device.mobile} {
  }
`
const RightHeader = styled(Subheader)`
  animation: rightHeader ease 1.4s;
  padding: 0;
  width: 150px;
  transform: translateX(100px) translateY(0px);

  @keyframes rightHeader {
    0% {
      opacity: 0;
      transform: translateX(100px) translateY(70%);
    }
    30% {
      opacity: 0;
      transform: translateX(100px) translateY(70%);
    }
    100% {
      opacity: 1;
      transform: translateX(100px) translateY(0);
    }
  }

  @media ${(props) => props.theme.device.mobile} {
  }
`

const BodyText = styled.p`
  font-family: ${(props) => props.theme.fonts.nueHaas65};
  color: ${(props) =>
    props.left ? props.theme.colors.stromboli : props.theme.colors.zambezi};

  &:first-of-type {
    margin-top: 0;
  }
`

const HeaderSection = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const BackgroundLeft = styled.div`
  display: fixed;
  background-color: ${(props) => props.theme.colors.harp};
  height: 100vh;
  width: 50vw;
`
const BackgroundRight = styled.div`
  display: fixed;
  background-color: ${(props) => props.theme.colors.islandSpice};
  z-index: 0;
  height: 100vh;
  width: 50vw;
`
const BgContainer = styled.div`
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const Background = () => (
  <BgContainer>
    <BackgroundLeft />
    <BackgroundRight />
  </BgContainer>
)

const BottomSection = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
`
const BottomTextSection = styled.div`
  width: 50vw;
  padding: 10px;
`

const TitleMobile = () => {
  const [showArrow, setShowArrow] = useState(true)

  const onScroll = useCallback(() => {
    setShowArrow(false)
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth',
    })
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > window.innerHeight) setShowArrow(false)
    })
  }, [])

  return (
    <>
      <Background />
      <TitleSection>
        <HeaderSection>
          <RightHeader>
            Presented
            <br />
            by OH! Open House
          </RightHeader>
          <StyledLogo />
          <LeftHeader>
            A choose-your-own-adventure experience inspired by stories around us
          </LeftHeader>
        </HeaderSection>
        <BottomSection>
          <BottomTextSection>
            <BodyText left>
              There are an abundance of art fairs, collector’s shows,
              exhibitions. But how many of them truly matter to our everyday
              lives? In <i>For the House; Against the House</i>, we invite
              curators to draw inspiration from personal stories of private art
              collectors and their collections, relating them into topical
              issues for debate.
            </BodyText>
            <BodyText left>
              Our first iteration for Singapore Art Week 2021 comprises two
              physical experiences that take the form of guided art
              tours-cum-debates. They are paired by their digital counterparts,
              two choose-your-own-adventure experiences based on different
              propositions.
            </BodyText>
          </BottomTextSection>
          <BottomTextSection>
            <BodyText>
              In{' '}
              <i>
                Passion Made Impossible: Should We Have Our Own Ambitions in
                Singapore?
              </i>
              , we invite you to consider the circumstances in which an
              individual is able to make decisions, based on the society and
              structures within which he lives.
            </BodyText>
            <BodyText>
              In <i>Judging Labels: Are They Useful?</i>, we present to you the
              socio-historical structures and influential people that can shape
              your future and how you will be viewed and remembered by history.
            </BodyText>
          </BottomTextSection>
        </BottomSection>
        <Link to="/select-debate">
          <StyledButton>Start Experience</StyledButton>
        </Link>
        <StyledArrow show={showArrow} onClick={onScroll} />
      </TitleSection>
    </>
  )
}

export default TitleMobile
