import styled, { css } from 'styled-components'
import { ReactComponent as Logo } from '../assets/img/fthath-logo-white.svg'
import HeaderLogo from '../components/HeaderLogo'

const MenuContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.gableGreen};
  display: flex;
  flex-direction: row;

  @media ${(props) => props.theme.device.mobile} {
    position: initial;
    height: auto;
    flex-direction: column;
  }
`
const MenuSectionContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-of-type {
    border-right: 1px solid white;
    @media ${(props) => props.theme.device.mobile} {
      border: none;
    }
  }
  @media ${(props) => props.theme.device.mobile} {
    width: auto;
    padding: 20px;
  }
`
const MenuSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px 0;
  min-width: 80%;
  ${(props) => css`
    justify-content: ${props.left ? 'space-between' : 'space-around'};
  `}
`
const MenuSection = ({ children, ...props }) => (
  <MenuSectionContainer {...props}>
    <MenuSectionContent {...props}>{children}</MenuSectionContent>
  </MenuSectionContainer>
)

const StyledLogo = styled(Logo)`
  width: 80%;
  margin: 0 auto;

  @media ${(props) => props.theme.device.mobile} {
    margin-top: 50px;
  }
`

const DescriptionContainer = styled.div`
  margin: 0 auto;
  width: 600px;
  height: 75vh;
  overflow-y: auto;

  @media ${(props) => props.theme.device.mobile} {
    width: auto;
    height: auto;
    overflow-y: hidden;
  }
`
const DescriptionHeader = styled.h1`
  font-family: ${(props) => props.theme.fonts.dreamOrphans};
  color: ${(props) => props.theme.colors.harp};
`
const DescriptionP = styled.p`
  font-family: ${(props) => props.theme.fonts.nueHaas65};
  color: ${(props) => props.theme.colors.harp};
`
const DescriptionItalic = styled.span`
  font-family: ${(props) => props.theme.fonts.nueHaas65};
  color: ${(props) => props.theme.colors.harp};
  font-style: italic;
`

const LogoContainer = styled.div`
  position: fixed;
  top: 20px;
  left: 20px;

  @media ${(props) => props.theme.device.mobile} {
    position: absolute;
  }
`

const StyledA = styled.a`
  font-family: ${(props) => props.theme.fonts.nueHaas65};
  color: ${(props) => props.theme.colors.harp};
`

const About = () => (
  <MenuContainer>
    <MenuSection left>
      <StyledLogo />
    </MenuSection>
    <MenuSection>
      <DescriptionContainer>
        <DescriptionHeader>About Collectors & Curators</DescriptionHeader>

        <DescriptionP>
          DUO Collection x Syed Muhd Hafiz
          <br />
          <DescriptionItalic>
            Proposition - Judging Labels: Are they useful?
          </DescriptionItalic>
          <br />
          It’s like a match made in heaven. The DUO Collection started their
          collection with modern art, including those from Singapore’s
          ‘second-generation’ artists, before moving on to support contemporary
          art practices in Singapore and Southeast Asia. Whilst curator Hafiz
          has always been interested in challenging the notions of what it meant
          to be ‘second generation’. As a result? Their proposition invites you
          to think about labeling, and challenges you to look at the players
          involved in shaping the terms.
        </DescriptionP>

        <DescriptionP>
          Jo x John
          <br />
          <DescriptionItalic>
            Proposition - Passion Made Impossible: Should we have our own
            ambitions in Singapore?
          </DescriptionItalic>
          <br />A free-spirited pairing. Jo sees herself more as a ‘collector of
          art’ than an art collector. Her approach to collecting isn’t focused
          on particular artists. Instead, she buys artworks that resonate with
          her life, both personal and professional. Curator John left his job at
          the institution. He is now a freelance exhibition maker, taking on
          projects that matters. Their proposition is inspired by artists’
          anecdotes. They invite you to look into our system and structures and
          consider if it’s worth having our own ambitions.
        </DescriptionP>

        <DescriptionHeader>About OH! Open House</DescriptionHeader>
        <DescriptionP>
          We tell alternative stories of Singapore through art. We are art and
          heritage experience specialists. Since 2009, we have provided more
          than 20,000 people with the unique experience of appreciating art and
          heritage from strangers’ homes to underutilised public spaces. We work
          with artists, curators and collectors to present art in unconventional
          locations, or in surprising new ways. More about{' '}
          <StyledA href="https://ohopenhouse.org/" target="_blank">
            OH!
          </StyledA>
        </DescriptionP>

        <DescriptionHeader>About Singapore Art Week</DescriptionHeader>

        <DescriptionP>
          The pinnacle of Singapore’s visual arts scene returns in its 9th
          edition from ​22 to 30 January 2021​. It’ll celebrate the coming
          together of our vibrant artist community in unprecedented times as we
          simultaneously navigate new modes of artmaking, presentation and look
          forward to new possibilities for the future. Keeping to the theme of
          “Art Takes Over”, SAW 2021 will present over 100 arts events across
          both physical and digital spaces. SAW 2021 is a joint initiative by
          the National Arts Council (NAC)​, the ​Singapore Tourism Board (STB)​
          and the Singapore Economic Development Board (EDB)​. View full SAW
          2021 event line-up{' '}
          <StyledA href="https://www.artweek.sg/" target="_blank">
            here
          </StyledA>
          .
        </DescriptionP>
      </DescriptionContainer>
    </MenuSection>
    <LogoContainer>
      <HeaderLogo />
    </LogoContainer>
  </MenuContainer>
)

export default About
