import styled from 'styled-components'

export const Section = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.harp};
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
`

export const SectionLeft = styled.div`
  width: 50%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.harp};
  margin: 0;
  padding: 0;
`

export const SectionRight = styled.div`
  width: 50%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.islandSpice};
  margin: 0;
  padding: 0;
`

export default Section
