import { useState, useEffect, useCallback } from 'react'
import styled, { css } from 'styled-components'
import Section, { SectionLeft, SectionRight } from '../components/Section'
import Button from '../components/Button'
import TitleMobile from './TitleMobile'
import { ReactComponent as Logo } from '../assets/img/fthath-logo.svg'
import { ReactComponent as Arrow } from '../assets/img/arrow.svg'
import landingLeftImg from '../assets/img/landing-left.jpg'
import landingRightImg from '../assets/img/landing-right.jpg'
import { Link, useHistory } from 'react-router-dom'
import ReactGA from 'react-ga'

const TitleSection = styled(Section)`
  height: auto;

  @media ${(props) => props.theme.device.mobile} {
    height: 100vh;
  }
`

const StyledLogo = styled(Logo)`
  position: fixed;
  top: 20vh;
  right: calc(50vw - 202px);
  width: 400px;

  @media ${(props) => props.theme.device.mobile} {
    width: 220px;
    right: calc(50vw - 112px);
    top: 25vh;
  }
`

const StyledArrow = styled(Arrow)`
  position: fixed;
  bottom: 20px;
  right: 10px;
  cursor: pointer;
  height: 80px;
  transition: 0.3s ease-in-out;

  ${(props) => css`
    transform: rotate(${props.isAtBottom ? '0deg' : '180deg'});
  `}

  @media ${(props) => props.theme.device.mobile} {
    display: none;
  }
`

const StyledButton = styled(Button)`
  position: fixed;
  bottom: 40px;
  right: calc(50vw - 150px);
  width: 300px;
  justify-content: center;
  transition: 1s ease-in-out;

  @media ${(props) => props.theme.device.mobile} {
    padding: 10px;
    bottom: 20px;
    transform: translateY(0px);
  }
  ${(props) => css`
    transform: translateY(${props.showBtn ? '0px' : '300px'});
  `}
`

const Subheader = styled.h2`
  height: 100vh;
  margin: 0;
  font-family: ${(props) => props.theme.fonts.dreamOrphans};
  font-size: 2em;
  pointer-events: none;

  @media ${(props) => props.theme.device.mobile} {
    height: auto;
    font-size: 1.2em;
  }
`
const LeftHeader = styled(Subheader)`
  color: ${(props) => props.theme.colors.stromboli};
  width: 250px;
  transform: translateY(45%);
  padding-left: 30%;
  animation: leftHeader ease 1s;

  @keyframes leftHeader {
    0% {
      opacity: 0;
      transform: translateY(35%);
    }
    100% {
      opacity: 1;
      transform: translateY(45%);
    }
  }

  @media ${(props) => props.theme.device.mobile} {
    position: fixed;
    bottom: 20vh;
    left: 20px;
    padding: 0;
  }
`
const RightHeader = styled(Subheader)`
  transform: translateY(60%);
  padding-left: 40%;
  animation: rightHeader ease 1.4s;

  @keyframes rightHeader {
    0% {
      opacity: 0;
      transform: translateY(70%);
    }
    30% {
      opacity: 0;
      transform: translateY(70%);
    }
    100% {
      opacity: 1;
      transform: translateY(60%);
    }
  }

  @media ${(props) => props.theme.device.mobile} {
    padding: 0;
    position: fixed;
    top: 50px;
    padding-left: 20px;
    width: 45vw;
    transform: translateY(60%);
  }
`

const AboutContentContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  ${(props) =>
    props.left
      ? css`
          color: ${(props) => props.theme.colors.gableGreen};
          justify-content: flex-end;
          align-items: flex-end;
          transform: translateX(-17vw);
        `
      : css`
          color: ${(props) => props.theme.colors.zambezi};
          justify-content: flex-start;
          align-items: flex-start;
          transform: translateX(17vw);
        `}

  @media ${(props) => props.theme.device.mobile} {
    display: none;
  }
`
const AboutContentInner = styled.div`
  width: 400px;
  padding: 20px;
`
const AboutContent = ({ children, left, right }) => (
  <AboutContentContainer left={left} right={right}>
    <AboutContentInner>{children}</AboutContentInner>
  </AboutContentContainer>
)

const BodyText = styled.p`
  font-family: ${(props) => props.theme.fonts.nueHaas65};

  &:first-of-type {
    margin-top: 0;
  }
`

const AboutImg = styled.img`
  width: 100%;
`

const DonatePopup = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  z-index: 1;
  position: fixed;
  background: ${(props) => props.theme.colors.gableGreen};
  padding: 30px;
  top: 20px;
  left: 20px;
  width: 300px;
  animation: fadeIn ease 3s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-100px);
    }
    50% {
      opacity: 0;
      transform: translateY(-100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  @media ${(props) => props.theme.device.mobile} {
    display: none;
  }
`
const DonateHeader = styled.h2`
  font-family: ${(props) => props.theme.fonts.dreamOrphans};
  text-align: center;
  color: ${(props) => props.theme.colors.harp};
`
const DonateButton = styled(Button)`
  border: 1px solid ${(props) => props.theme.colors.harp};
  color: ${(props) => props.theme.colors.harp};
  width: 200px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
`
const CloseDonateBtn = styled.span`
  color: ${(props) => props.theme.colors.harp};
  font-family: ${(props) => props.theme.fonts.nueHaas55};
  font-size: 4em;
  position: fixed;
  transform: translateX(280px) translateY(-40px);
  cursor: pointer;
`

const Title = () => {
  const [isAtBottom, setIsAtBottom] = useState(false)
  const [showBtn, setShowBtn] = useState(false)
  const [showDonate, setShowDonate] = useState(true)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const history = useHistory()

  const onScroll = useCallback(() => {
    if (isAtBottom) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setIsAtBottom(false)
    } else {
      window.scrollTo({
        top: window.innerHeight,
        behavior: 'smooth',
      })
    }
  }, [isAtBottom])

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setIsAtBottom(window.scrollY === window.innerHeight ? true : false)
      if (window.scrollY > window.innerHeight / 2) setShowBtn(true)
    })

    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth < 768)
    })
  }, [])

  return isMobile ? (
    <TitleMobile />
  ) : (
    <>
      <DonatePopup show={showDonate}>
        <CloseDonateBtn onClick={() => setShowDonate(false)}>×</CloseDonateBtn>
        <DonateHeader>
          If you enjoy the experience, consider donating to OH!
        </DonateHeader>
        <DonateButton
          ghost
          onClick={() => {
            ReactGA.event({
              category: 'Button (SAW21)',
              label: 'SAW 21 - Donation (landing page pop up)',
              action: 'Clicked donation in landing page pop up',
            })
            history.push('/donation')
          }}
        >
          Donate Now
        </DonateButton>
      </DonatePopup>
      <TitleSection>
        <SectionLeft>
          <LeftHeader>
            A choose-your-own-adventure experience inspired by stories around us
          </LeftHeader>

          <AboutContent left>
            <BodyText>
              There are an abundance of art fairs, collector’s shows,
              exhibitions. But how many of them truly matter to our everyday
              lives? In <i>For the House; Against the House</i>, we invite
              curators to draw inspiration from personal stories of private art
              collectors and their collections, relating them into topical
              issues for debate.
            </BodyText>
            <AboutImg src={landingLeftImg} alt="art fairs" />
          </AboutContent>
        </SectionLeft>
        <SectionRight>
          <RightHeader>
            Presented
            <br />
            by OH! Open House
          </RightHeader>
          <AboutContent right>
            <AboutImg src={landingRightImg} alt="merlion" />
            <BodyText>
              Our first iteration for Singapore Art Week 2021 comprises two
              physical experiences that take the form of guided art
              tours-cum-debates. They are paired by their digital counterparts,
              two choose-your-own-adventure experiences based on different
              propositions.
            </BodyText>
            <BodyText>
              In{' '}
              <i>
                Passion Made Impossible: Should We Have Our Own Ambitions in
                Singapore?
              </i>
              , we invite you to consider the circumstances in which an
              individual is able to make decisions, based on the society and
              structures within which he lives.
            </BodyText>
            <BodyText>
              In <i>Judging Labels: Are They Useful?</i>, we present to you the
              socio-historical structures and influential people that can shape
              your future and how you will be viewed and remembered by history.
            </BodyText>
          </AboutContent>
        </SectionRight>
        <StyledLogo />
        <Link to="/select-debate">
          <StyledButton showBtn={showBtn}>Start Experience</StyledButton>
        </Link>
        <StyledArrow onClick={onScroll} isAtBottom={isAtBottom} />
      </TitleSection>
    </>
  )
}

export default Title
