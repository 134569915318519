import styled from 'styled-components'
import { ReactComponent as NavLogo } from '../assets/img/nav-logo.svg'
import { ReactComponent as NavLogoGrey } from '../assets/img/nav-logo-grey.svg'
import { ReactComponent as NavLogoGreen } from '../assets/img/nav-logo-green.svg'

const StyledNavLogo = styled(NavLogo)`
  height: 80px;
  width: 80px;
  margin-right: 10px;
  @media ${(props) => props.theme.device.mobile} {
    height: 50px;
    width: 50px;
  }
`
const StyledNavLogoGrey = styled(NavLogoGrey)`
  height: 80px;
  width: 80px;
  margin-right: 10px;
  @media ${(props) => props.theme.device.mobile} {
    height: 50px;
    width: 50px;
  }
`
const StyledNavLogoGreen = styled(NavLogoGreen)`
  height: 80px;
  width: 80px;
  margin-right: 10px;

  @media ${(props) => props.theme.device.mobile} {
    height: 50px;
    width: 50px;
  }
`
const LogoText = styled.h1`
  font-family: ${(props) => props.theme.fonts.dreamOrphans};
  font-size: 2em;
  color: ${(props) =>
    props.grey
      ? props.theme.colors.zambezi
      : props.green
      ? props.theme.colors.gableGreen
      : 'white'};
  margin: 0;

  @media ${(props) => props.theme.device.mobile} {
    font-size: 1em;
  }
`
const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const HeaderLogo = ({ grey = false, green = false }) => (
  <LogoContainer>
    {grey ? (
      <StyledNavLogoGrey />
    ) : green ? (
      <StyledNavLogoGreen />
    ) : (
      <StyledNavLogo />
    )}
    <LogoText grey={grey} green={green}>
      FOR THE HOUSE
      <br />
      AGAINST THE HOUSE
    </LogoText>
  </LogoContainer>
)

export default HeaderLogo
