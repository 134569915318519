import styled, { css } from 'styled-components'
import HeaderLogo from '../components/HeaderLogo'
import nac from '../assets/img/logos/nac.png'
import saw from '../assets/img/logos/saw.png'
import sgca from '../assets/img/logos/sgca.png'

const MenuContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.islandSpice};
  display: flex;
  flex-direction: row;

  @media ${(props) => props.theme.device.mobile} {
    position: initial;
    flex-direction: column;
    width: auto;
    height: auto;
  }
`
const MenuSectionContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-of-type {
    border-right: 1px solid ${(props) => props.theme.colors.zambezi};

    @media ${(props) => props.theme.device.mobile} {
      border: none;
    }
  }

  @media ${(props) => props.theme.device.mobile} {
    width: auto;
  }
`
const MenuSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px 0;
  min-width: 80%;
  ${(props) => css`
    justify-content: ${props.left ? 'space-between' : 'space-around'};
  `}
  @media ${(props) => props.theme.device.mobile} {
    &:first-of-type {
      margin-top: 100px;
    }

    align-items: center;
    justify-content: center;
    padding: 20px;
  }
`
const MenuSection = ({ children, ...props }) => (
  <MenuSectionContainer {...props}>
    <MenuSectionContent {...props}>{children}</MenuSectionContent>
  </MenuSectionContainer>
)

const DescriptionContainer = styled.div`
  margin: 0 auto;
  width: 600px;
  height: 75vh;
  overflow-y: auto;

  @media ${(props) => props.theme.device.mobile} {
    width: auto;
    height: auto;
    overflow-y: hidden;
  }
`
const DescriptionH1 = styled.h1`
  font-family: ${(props) => props.theme.fonts.dreamOrphans};
  color: ${(props) => props.theme.colors.zambezi};
  font-size: 3em;
  @media ${(props) => props.theme.device.mobile} {
  }
`
const DescriptionH2 = styled.h2`
  font-family: ${(props) => props.theme.fonts.dreamOrphans};
  color: ${(props) => props.theme.colors.zambezi};
  font-size: 2em;
  @media ${(props) => props.theme.device.mobile} {
  }
`
const DescriptionP = styled.p`
  font-family: ${(props) => props.theme.fonts.nueHaas65};
  color: ${(props) => props.theme.colors.zambezi};
`
const Bold = styled.span`
  font-family: ${(props) => props.theme.fonts.nueHaas75};
  font-weight: bold;
  color: ${(props) => props.theme.colors.gableGreen};
`

const LogoContainer = styled.div`
  position: fixed;
  top: 20px;
  left: 20px;

  @media ${(props) => props.theme.device.mobile} {
    position: absolute;
  }
`

const LogoImage = styled.img`
  height: 150px;
  margin-top: 20px;
`
const ImageRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${(props) => props.theme.device.mobile} {
    flex-direction: column;
  }
`

const Credits = () => (
  <MenuContainer>
    <MenuSection left>
      <Bold>Part of</Bold>
      <ImageRow>
        <LogoImage src={saw} />
        <LogoImage src={sgca} />
      </ImageRow>
      <br />
      <Bold>Supported by</Bold>
      <div>
        <LogoImage src={nac} />
      </div>
    </MenuSection>
    <MenuSection>
      <DescriptionContainer>
        <DescriptionH1>Credits</DescriptionH1>
        <DescriptionP>
          OH! would also love to thank the following people for supporting and
          believing in us all these years: BinjaiTree, Deutsche Bank, Ginga
          Petroleum.
        </DescriptionP>
        <DescriptionH2>With Special Thanks To</DescriptionH2>
        <DescriptionP>
          <Bold>Collectors</Bold>
          <br />
          DUO Collection
          <br />
          Jo
        </DescriptionP>
        <DescriptionH2>Experience Development</DescriptionH2>
        <DescriptionP>
          <Bold>Curators</Bold>
          <br />
          John Tung
          <br />
          Syed Muhd Hafiz
        </DescriptionP>
        <DescriptionP>
          <Bold>Graphic & Experience Designer</Bold>
          <br />
          Factory
        </DescriptionP>
        <DescriptionP>
          <Bold>Web Developer</Bold>
          <br />
          Keith Chia
        </DescriptionP>
        <DescriptionP>
          <Bold>Editor and Copyeditor</Bold>
          <br />
          Yeo Boon Ping
        </DescriptionP>
        <DescriptionP>
          <Bold>Artwork Photographer</Bold>
          <br />
          AlvieAlive
        </DescriptionP>
        <DescriptionP>
          <Bold>Producer</Bold>
          <br />
          Sophy Tio
        </DescriptionP>
        <DescriptionH2>Presented by OH! Open House</DescriptionH2>
        <DescriptionP>
          <Bold>Board members</Bold>
          <br />
          Alan Oei
          <br />
          Linda Neo
          <br />
          Lisa Robins
          <br />
          Oliver Bettin
          <br />
          Philip Morgan
          <br />
          Ryan Su
          <br />
          Seng Yu Jin
          <br />
          Tan Kheng Hua
          <br />
          Tan Sue Yen
        </DescriptionP>
        <DescriptionP>
          <Bold>Executive Director</Bold>
          <br />
          Alan Oei
        </DescriptionP>
        <DescriptionP>
          <Bold>Assistant Director</Bold>
          <br />
          Lim Su Pei
        </DescriptionP>
        <DescriptionP>
          <Bold>Assistant Curators</Bold>
          <br />
          Daniel Chong
          <br />
          Kirti Bhaskar Upadhyaya
        </DescriptionP>
        <DescriptionP>
          <Bold>Community & Partnerships Manager</Bold>
          <br />
          Tang Hui Shi
        </DescriptionP>
        <DescriptionP>
          <Bold>Marcomms Manager</Bold>
          <br />
          Carissa Low
        </DescriptionP>
        <DescriptionP>
          <Bold>Marcomms Trainee</Bold>
          <br />
          Marvin Lee
        </DescriptionP>
      </DescriptionContainer>
    </MenuSection>
    <LogoContainer>
      <HeaderLogo grey />
    </LogoContainer>
  </MenuContainer>
)

export default Credits
