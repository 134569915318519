import styled, { css } from 'styled-components'
import Button from '../components/Button'
import HeaderLogo from '../components/HeaderLogo'
import ReactGA from 'react-ga'

const MenuContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.islandSpice};
  display: flex;
  flex-direction: row;

  @media ${(props) => props.theme.device.mobile} {
    position: initial;
    flex-direction: column;
    width: auto;
    height: auto;
  }
`
const MenuSectionContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-of-type {
    border-right: 1px solid ${(props) => props.theme.colors.zambezi};

    @media ${(props) => props.theme.device.mobile} {
      border: none;
    }
  }

  @media ${(props) => props.theme.device.mobile} {
    width: auto;
  }
`
const MenuSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px 0;
  min-width: 80%;
  ${(props) => css`
    justify-content: ${props.left ? 'space-between' : 'space-around'};
  `}
`
const MenuSection = ({ children, ...props }) => (
  <MenuSectionContainer {...props}>
    <MenuSectionContent {...props}>{children}</MenuSectionContent>
  </MenuSectionContainer>
)

const DescriptionContainer = styled.div`
  margin: 0 auto;
  width: 600px;
  @media ${(props) => props.theme.device.mobile} {
    width: auto;
    padding: 20px;
  }
`
const DescriptionHeader = styled.h1`
  font-family: ${(props) => props.theme.fonts.dreamOrphans};
  color: ${(props) => props.theme.colors.zambezi};
  font-size: 48px;
  margin: 0 10%;
  @media ${(props) => props.theme.device.mobile} {
    margin-top: 80px;
  }
`
const DescriptionP = styled.p`
  font-family: ${(props) => props.theme.fonts.nueHaas65};
  color: ${(props) => props.theme.colors.zambezi};
`

const LogoContainer = styled.div`
  position: fixed;
  top: 20px;
  left: 20px;

  @media ${(props) => props.theme.device.mobile} {
    position: absolute;
  }
`

const DonateButton = styled(Button)`
  width: 100%;
  text-align: left;
  padding: 20px 40px;
  margin-top: 20px;
`
const StyledLink = styled.a`
  text-decoration: none;
`

const Donation = () => (
  <MenuContainer>
    <MenuSection left>
      <DescriptionHeader>
        Your support helps us continue to tell stories.
      </DescriptionHeader>
    </MenuSection>
    <MenuSection>
      <DescriptionContainer>
        <DescriptionP>
          The arts scene in Singapore has been greatly impacted by COVID-19. We
          expect donations to our cause to drop by 80%. Even though we can run
          physical programmes now, the capacity is halved given the ongoing
          situation. With higher production costs due to Safe Management
          Measures (SMM) and limited ticket revenue, we need your help to
          continue telling stories that would otherwise disappear in Singapore’s
          ever-changing landscape.
        </DescriptionP>
        <DescriptionP>
          Any amount makes a difference. From funding zoom subscriptions so we
          can conduct volunteer training, to paying artists and contractors,
          your contribution is greatly appreciated!
        </DescriptionP>
        <StyledLink
          href="https://ohopenhouse.give.asia/"
          target="_blank"
          rel="noreferrer"
        >
          <DonateButton
            arrow
            ghost
            onClick={() =>
              ReactGA.event({
                category: 'Button (SAW21)',
                label: 'SAW 21 - Donation link (donation page)',
                action: 'Clicked donation button to Give Asia',
              })
            }
          >
            Donate now
          </DonateButton>
        </StyledLink>
      </DescriptionContainer>
    </MenuSection>
    <LogoContainer>
      <HeaderLogo grey />
    </LogoContainer>
  </MenuContainer>
)

export default Donation
