import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'
import styled, { css } from 'styled-components'
import Button from '../components/Button'
import HeaderLogo from '../components/HeaderLogo'

const MenuContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;

  @media ${(props) => props.theme.device.mobile} {
    position: initial;
    width: auto;
    height: 100vh;
  }
`
const MenuSectionContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20vh;
  align-items: flex-start;

  @media ${(props) => props.theme.device.mobile} {
    width: 50vw;
    padding-top: 50px;
  }

  ${(props) => css`
    background-color: ${props.left
      ? props.theme.colors.harp
      : props.theme.colors.islandSpice};
    color: ${props.left
      ? props.theme.colors.stromboli
      : props.theme.colors.zambezi};
  `}
`
const MenuSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px 0;
  min-width: 80%;
  ${(props) => css`
    justify-content: ${props.left ? 'space-between' : 'space-around'};
  `}
`
const MenuSection = ({ children, ...props }) => (
  <MenuSectionContainer {...props}>
    <MenuSectionContent {...props}>{children}</MenuSectionContent>
  </MenuSectionContainer>
)

const DescriptionContainer = styled.div`
  margin: 0 20px;
  max-width: 600px;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media ${(props) => props.theme.device.mobile} {
    width: auto;
    padding: 20px;
    margin: 0;
  }
`
const HeaderNumber = styled.p`
  font-family: ${(props) => props.theme.fonts.nueHaas65};
  font-size: 6em;
  margin: 0;

  @media ${(props) => props.theme.device.mobile} {
    font-size: 5em;
  }
`
const DescriptionHeader = styled.h1`
  font-family: ${(props) => props.theme.fonts.dreamOrphans};
  font-size: 3em;
  margin: 0;
  @media ${(props) => props.theme.device.mobile} {
    font-size: 1.3em;
    margin-bottom: 30px;
  }
`
const DescriptionP = styled.p`
  margin: 30px auto;
  font-family: ${(props) => props.theme.fonts.nueHaas65};

  @media ${(props) => props.theme.device.mobile} {
    display: none;
  }
`
const MobileP = styled.p`
  margin: 30px auto;
  font-size: 0.9em;
  font-family: ${(props) => props.theme.fonts.nueHaas65};
`
const ReadLink = styled.span`
  font-family: ${(props) => props.theme.fonts.nueHaas75};
  border-bottom: 1px solid ${(props) => props.theme.colors.gableGreen};
  cursor: pointer;
`

const LogoContainer = styled.div`
  position: fixed;
  top: 20px;
  left: 20px;

  @media ${(props) => props.theme.device.mobile} {
    position: absolute;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
`
const StyledButton = styled(Button)`
  width: 100%;

  @media ${(props) => props.theme.device.mobile} {
    font-size: 1em;
    position: fixed;
    bottom: 20px;
    width: 40%;
    padding: 10px;
    padding-left: 15px;
  }
`
const RightButton = styled(StyledButton)`
  &:hover {
    background-color: ${(props) => props.theme.colors.gableGreen};
    color: ${(props) => props.theme.colors.islandSpice};
  }
`

const FadeInContent = styled.div`
  margin-bottom: 30px;
  animation: fadeUp ease 0.5s;

  @keyframes fadeUp {
    0% {
      transform: translateY(30px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`

const ExpandedContent = styled.div`
  margin-bottom: 30px;
  animation: fadeIn ease 0.5s;

  @keyframes fadeIn {
    0% {
      transform: translateY(-30px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`

const SelectDebate = () => {
  const [mobile, setMobile] = useState(window.innerWidth < 768)
  const [leftExpanded, setLeftExpanded] = useState(false)
  const [rightExpanded, setRightExpanded] = useState(false)

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth < 768) setMobile(true)
      else setMobile(false)
    })
  }, [])

  const handleStartLabels = () =>
    ReactGA.event({
      category: 'Button (SAW21)',
      label: 'SAW 21 - Start experience (Labels)',
      action: 'Click Start experience (Labels)',
    })

  const handleStartPassion = () =>
    ReactGA.event({
      category: 'Button (SAW21)',
      label: 'SAW 21 - Start experience (Passion)',
      action: 'Click Start experience (Passion)',
    })

  return (
    <MenuContainer>
      <MenuSection left>
        <DescriptionContainer>
          {leftExpanded ? (
            <ExpandedContent>
              <MobileP>
                In an environment shaped largely by top-down policies, the
                definition of success can change on a whim according to the
                current goals of the state. How, then, can the ambition of the
                everyday individual keep in step with constantly changing state
                objectives? Should we even harbour personal ambitions, or let
                the state decide our dreams?
              </MobileP>
              <ReadLink onClick={() => setLeftExpanded(false)}>
                Read less -
              </ReadLink>
            </ExpandedContent>
          ) : (
            <FadeInContent>
              <HeaderNumber>1</HeaderNumber>
              <DescriptionHeader>
                Passion Made Impossible: Should We Have Our Own Ambitions in
                Singapore?
              </DescriptionHeader>
              {mobile && (
                <ReadLink onClick={() => setLeftExpanded(true)}>
                  Read more +
                </ReadLink>
              )}
              <DescriptionP>
                In an environment shaped largely by top-down policies, the
                definition of success can change on a whim according to the
                current goals of the state. How, then, can the ambition of the
                everyday individual keep in step with constantly changing state
                objectives? Should we even harbour personal ambitions, or let
                the state decide our dreams?
              </DescriptionP>
            </FadeInContent>
          )}
          <StyledLink
            to="/passion-made-impossible"
            onClick={handleStartPassion}
          >
            <StyledButton ghost>
              {mobile ? 'Select experience' : 'Select this experience'}
            </StyledButton>
          </StyledLink>
        </DescriptionContainer>
      </MenuSection>
      <MenuSection>
        <DescriptionContainer>
          {rightExpanded ? (
            <ExpandedContent>
              <MobileP>
                We use labels in our everyday life; grouping objects and people
                into convenient categories and assigning value judgments. In the
                context of art, there are labels ‘second generation’, ‘emerging
                artists’ etc. Who do these terms matter to? How do they
                influence who we are?
              </MobileP>
              <ReadLink onClick={() => setRightExpanded(false)}>
                Read less -
              </ReadLink>
            </ExpandedContent>
          ) : (
            <FadeInContent>
              <HeaderNumber>2</HeaderNumber>
              <DescriptionHeader>
                Judging Labels: Are They Useful?
              </DescriptionHeader>
              {mobile && (
                <ReadLink onClick={() => setRightExpanded(true)}>
                  Read more +
                </ReadLink>
              )}
              <DescriptionP>
                We use labels in our everyday life; grouping objects and people
                into convenient categories and assigning value judgments. In the
                context of art, there are labels ‘second generation’, ‘emerging
                artists’ etc. Who do these terms matter to? How do they
                influence who we are?
              </DescriptionP>
            </FadeInContent>
          )}

          <StyledLink to="/judging-labels" onClick={handleStartLabels}>
            <RightButton ghost>
              {mobile ? 'Select experience' : 'Select this experience'}
            </RightButton>
          </StyledLink>
        </DescriptionContainer>
      </MenuSection>
      <LogoContainer>
        <HeaderLogo green />
      </LogoContainer>
    </MenuContainer>
  )
}

export default SelectDebate
