import styled from 'styled-components'
import ReactModal from 'react-modal'
import { theme } from '../globalStyle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const modalStyle = {
  overlay: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
  content: {
    overflowX: 'hidden',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: 400,
    height: 500,
    padding: 20,
    position: 'initial',
    backgroundColor: theme.colors.islandSpice,
  },
}

const CloseModalBtn = styled.span`
  color: white;
  font-family: ${(props) => props.theme.fonts.nueHaas55};
  font-size: 6em;
  position: fixed;
  transform: translateX(270px) translateY(-100px);
  cursor: pointer;

  @media ${(props) => props.theme.device.mobile} {
    color: ${(props) => props.theme.colors.gableGreen};
    transform: translateX(-140px) translateY(-50px);
  }
`

const SocialHeader = styled.h1`
  font-family: ${(props) => props.theme.fonts.dreamOrphans};
  color: ${(props) => props.theme.colors.gableGreen};
  text-align: center;
`

const SocialText = styled.p`
  font-family: ${(props) => props.theme.fonts.nueHaas65};
  color: ${(props) => props.theme.colors.gableGreen};
  text-align: center;
`

const SocialRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 30px;
  margin: 0 0 20px;
  align-items: center;
  justify-content: space-between;
`

const SocialBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  width: 80px;
  height: 80px;
  margin: 0 10px;
  border: 1px solid ${(props) => props.theme.colors.gableGreen};
  background: ${(props) => props.theme.colors.islandSpice};
  cursor: pointer;
`

const ShareModal = ({
  shareModalOpen,
  setShareModalOpen,
  shareHeader,
  shareBody,
  node,
}) => {
  const SocialImage = node.image
    ? styled(node.image)`
        max-width: 90%;
        margin: 0 auto;
        border: 1px solid black;
        padding: 10px;

        @media ${(props) => props.theme.device.mobile} {
          width: 100vw;
          padding: 0;
          border: none;
        }
      `
    : styled.p``

  return (
    <ReactModal style={modalStyle} isOpen={shareModalOpen}>
      <CloseModalBtn onClick={() => setShareModalOpen(false)}>×</CloseModalBtn>
      <SocialHeader>{node.headerText || node.rightHeaderText}</SocialHeader>
      {node.image && <SocialImage />}
      <SocialText>Share your results</SocialText>
      <SocialRow>
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://www.facebook.com/sharer/sharer.php?u=http://saw.ohopenhouse.online&quote=${shareHeader}%0A%0A${shareBody}`}
        >
          <SocialBtn>
            <FontAwesomeIcon
              icon={['fab', 'facebook-f']}
              size="2x"
              color={theme.colors.stromboli}
            />
          </SocialBtn>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href={`http://twitter.com/share?text=${shareHeader}%0A%0A${shareBody}%0A%0A&url=http://saw.ohopenhouse.online`}
        >
          <SocialBtn>
            <FontAwesomeIcon
              icon={['fab', 'twitter']}
              size="2x"
              color={theme.colors.stromboli}
            />
          </SocialBtn>
        </a>
      </SocialRow>
    </ReactModal>
  )
}

export default ShareModal
