import styled, { css } from 'styled-components'
import { ReactComponent as Arrow } from '../assets/img/arrow.svg'
import { ReactComponent as LightArrow } from '../assets/img/arrow-light.svg'

const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background-color: ${(props) => props.theme.colors.zambezi};
  color: ${(props) => props.theme.colors.islandSpice};
  border: none;
  cursor: pointer;
  border-radius: 100px;
  font-family: ${(props) => props.theme.fonts.nueHaas75};
  font-size: 24px;
  transition: background-color 0.5s;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.gableGreen};
  }

  ${(props) =>
    props.ghost &&
    css`
      background: rgba(0, 0, 0, 0);
      border: 1px solid ${(props) => props.theme.colors.gableGreen};
      color: ${(props) => props.theme.colors.gableGreen};

      &:hover {
        border: 1px solid ${(props) => props.theme.colors.islandSpice};
        background-color: ${(props) => props.theme.colors.islandSpice};
      }
    `}
`
const StyledArrow = styled(Arrow)`
  min-width: 30px;
  transform: rotate(90deg);
  cursor: pointer;
  margin-left: 20px;

  @media ${(props) => props.theme.device.mobile} {
    min-width: 20px;
  }
`
const StyledLightArrow = styled(LightArrow)`
  min-width: 30px;
  transform: rotate(90deg);
  cursor: pointer;
  margin-left: 20px;

  @media ${(props) => props.theme.device.mobile} {
    min-width: 20px;
  }
`

const Button = ({ children, arrow, lightArrow, ...props }) => (
  <StyledButton {...props}>
    <>{children}</>
    {arrow ? lightArrow ? <StyledLightArrow /> : <StyledArrow /> : null}
  </StyledButton>
)
export default Button
