import { useState, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import GlobalStyle, { theme } from './globalStyle'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation,
} from 'react-router-dom'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import Title from './pages/Title'
import About from './pages/About'
import Navigation from './pages/Navigation'
import Donation from './pages/Donation'
import PassionMadeImpossible from './pages/PassionMadeImpossible'
import JudgingLabels from './pages/JudgingLabels'
import SelectDebate from './pages/SelectDebate'
import Credits from './pages/Credits'

library.add(fab)

function App() {
  const history = useHistory()
  const location = useLocation()
  const [hamburgerColor, setHamburgerColor] = useState(theme.colors.harp)

  useEffect(() => {
    // ReactGA.initialize('UA-57911784-4') // staging id
    ReactGA.initialize('UA-57911784-3') // production id
    const options = {
      autoConfig: true, // set pixel's autoConfig
      debug: false, // enable logs
    }
    ReactPixel.init('1279481759053160', undefined, options)
  }, [])

  useEffect(() => {
    const color =
      location.pathname === '/about'
        ? theme.colors.harp
        : theme.colors.gableGreen
    setHamburgerColor(color)
  }, [location])

  useEffect(() => {
    history.listen((location) => {
      ReactGA.pageview(location.pathname)
      ReactPixel.pageView(location.pathname)

      const color =
        location.pathname === '/about'
          ? theme.colors.harp
          : theme.colors.gableGreen
      setHamburgerColor(color)
    })
  }, [history])

  return (
    <ThemeProvider theme={theme}>
      <Navigation hamburgerColor={hamburgerColor} />
      <Switch>
        <Route exact path="/">
          <Title />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/donation">
          <Donation />
        </Route>
        <Route path="/select-debate">
          <SelectDebate />
        </Route>
        <Route path="/credits">
          <Credits />
        </Route>
        <Route path="/passion-made-impossible">
          <PassionMadeImpossible setHamburgerColor={setHamburgerColor} />
        </Route>
        <Route path="/judging-labels">
          <JudgingLabels setHamburgerColor={setHamburgerColor} />
        </Route>
      </Switch>
      <GlobalStyle />
    </ThemeProvider>
  )
}

const RoutedApp = () => (
  <Router>
    <App />
  </Router>
)

export default RoutedApp
