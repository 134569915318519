import singapore from '../assets/img/pmi/passion-made-possible.jpg'
import image1 from '../assets/img/pmi/1.jpg'
import image0 from '../assets/img/pmi/0.jpg'
import JS2A from '../assets/img/pmi/JS2A.jpg'
import JS3D from '../assets/img/pmi/JS3D.jpg'
import JS4B from '../assets/img/pmi/JS4B.jpg'
import JS4C from '../assets/img/pmi/JS4C.jpg'
import JS4D from '../assets/img/pmi/JS4D.jpg'
import JS4E from '../assets/img/pmi/JS4E.jpg'
import JS5B from '../assets/img/pmi/JS5B.jpg'
import JS5D from '../assets/img/pmi/JS5D.jpg'
import MM3A_JS2B from '../assets/img/pmi/MM3A_JS2B.jpg'
import MM3D from '../assets/img/pmi/MM3D.jpg'
import MM4A from '../assets/img/pmi/MM4A.jpg'
import MM5B from '../assets/img/pmi/MM5B.jpg'
import MM5C from '../assets/img/pmi/MM5C.jpg'
import MM5D from '../assets/img/pmi/MM5D.jpg'
import MM6A_JS6A from '../assets/img/pmi/MM6A_JS6A.jpg'
import MM6B_JS6B from '../assets/img/pmi/MM6B_JS6B.jpg'
import MM6C_JS6C from '../assets/img/pmi/MM6C_JS6C.jpg'

const tree = [
  {
    id: 'unused',
    image: (props) => <img {...props} src={singapore} alt="singapore" />,
    rightHeaderText: 'Passion Made Possible',
    bodyText: [
      "In August 2017, the Singapore Tourism Board and Singapore Economic Development Board launched a unified brand for marketing Singapore to the world: Passion Made Possible. Intended to embody “the country’s track record and tenacity to fulfil passions and continually create new possibilities”, this new brand was meant to signify Singapore's shift from being “an investment-driven economy to one that will be led by innovation”.",
      'The implementation of new campaigns and slogans is indeed indicative of shifts in both the state’s long-term goals and more immediate strategies. But, on an individual level, how much can the ambitions of the everyday person adapt to changing state objectives? In an environment largely structured by top-down policies, can the individual do anything but tread water and go with what is dictated from above?',
      'We grow up with expectations thrust upon us – by families, friends, relations, and state – and develop our own along our journeys. To an extent, there is no escaping expectations; the authentic ones, then, are those that stay with us through our lives. Yet, when faced with choices, will we stay true to our goal, or will we take the path already trodden black for us?',
    ],
    next: '0',
  },
  {
    id: 'start',
    rightHeaderText: 'Cultural Desert or Artistic Oasis?',
    image: (props) => <img {...props} src={image0} alt="desert" />,
    rightBodyText: [
      'In this game, you have the option of playing as John the Noble Savage or World Controller Mustapha Mond.',
      'Playing as each character will offer you different encounters. Will your decisions lead to the creation of a cultural desert or an artistic oasis? What will become of you and your adversary?',
      'The characters in this game are purely fictional; any similarities to people living or dead are purely coincidental.',
    ],
    choices: [{ to: '1', text: 'Choose your character' }],
  },
  {
    id: '1',
    image: (props) => <img {...props} src={image1} alt="choose" />,
    rightHeaderText: 'Who will you play as?',
    bodyText: [
      '!Mustapha Mond',
      'As a World Controller, you have access to information withheld from most. With this power of knowledge, you shall pass judgment on events you encounter on your journey.',
      '!John Savage',
      'As the Noble Savage, you know very little of this Brave New World you have arrived in, let alone possess much general knowledge. You have, however, read and memorised the entire works of William Shakespeare. Your words will influence different individuals on your journey.',
    ],
    choices: [
      { to: 'MM1', text: 'Mustapha Mond' },
      { to: 'JS1', text: 'John Savage' },
    ],
  },
  {
    id: 'MM1',
    bodyText:
      'Ah … The shining Central Business District … We are an economic machine. There is prosperity fruiting on the trees planted by my prudent predecessors. But the people are bored. If we do not entertain them, productivity will fall. Let me begin by:',
    choices: [
      { to: 'MM2A', text: 'Listening to people on the ground.' },
      { to: 'MM2B', text: 'Forming a ministerial committee.' },
    ],
  },
  {
    id: 'MM2A',
    bodyText:
      'A man comes up to you and proposes to create an independent arts centre for you. Although he has been critical of the administration in the past, you agree that this is a good idea: the centre would meet our goals of developing new arts and cultural practices.',
    choices: [
      {
        to: 'MM3A',
        text:
          'We will implement and expand on his idea, and pass it off as our own. Only then will we grant him a venue under this scheme.',
      },
      {
        to: 'MM3B',
        text:
          'Such a venue is good and necessary. We should support him and start building it immediately.',
      },
    ],
  },
  {
    id: 'MM2B',
    bodyText:
      'A committee has been formed. After in-depth research and consideration, the committee comes back to you with two areas of focus. However, because of a tight budget, you can only choose one.',
    choices: [
      {
        to: 'MM3C',
        text: 'Focus on developing people and their capabilities.',
      },
      { to: 'MM3D', text: 'Focus on developing infrastructure.' },
    ],
  },
  {
    id: 'MM3A',
    image: (props) => <img {...props} src={MM3A_JS2B} alt="choose" />,
    bodyText: [
      'A scheme has been created to provide artists with studio spaces. However, many artists who do not trust the system, like a group called the 4th Corridor, have continued to establish their own arts sites in places like shopping malls.',
      'A recent event held by 4th Corridor has been sensationalised in the media and is attracting public ire. This is the perfect opportunity to clamp down on these rabble rousers.',
    ],
    choices: [
      {
        to: 'MM4A',
        text:
          'We cannot let these artists proliferate. It would be wise to shut them down now so others will learn to toe the line.',
      },
      {
        to: 'MM4B',
        text:
          'People are easily offended. They have the option of turning their eyes away from what they do not wish to see. Leaving the artists alone would suffice.',
      },
    ],
  },
  {
    id: 'MM3B',
    bodyText:
      'The arts centre spearheaded by the man has cast us in a positive light. People from around the world are eager to visit; locals are also keen to learn more about the arts. We can capitalise on this interest to further develop the ecosystem.',
    choices: [
      {
        to: 'MM4B',
        text:
          'Let us introduce residencies for artists from around the world to gather and create artwork in our fine city.',
      },
      {
        to: 'MM4C',
        text:
          'We should instil an understanding of the arts in people of all ages through the education system.',
      },
    ],
  },
  {
    id: 'MM3C',
    bodyText:
      'How should we best develop the people of our fine city? Do we want to nurture them as artists so the supply of art increases, or cultivate their demand for art? Or are supply of, and demand for, art inextricable from one another?',
    choices: [
      {
        to: 'MM4C',
        text:
          'We should place trained practitioners in schools so that they will teach and inspire young children to become artists themselves.',
      },
      {
        to: 'MM4D',
        text:
          'Let us fund a wide range of craft events in neighbourhoods around the island, so that all may partake in the joy of making.',
      },
    ],
  },
  {
    id: 'MM3D',
    image: (props) => <img {...props} src={MM3D} alt="choose" />,
    bodyText:
      'A museum has been built for the presentation of artwork. However, without good exhibitions, it is merely an empty shell. What should we display in the museum?',
    choices: [
      {
        to: 'MM4D',
        text:
          'Let us showcase a diverse range of contemporary art from all corners of the earth so that people may come to understand the world around them.',
      },
      {
        to: 'MM4E',
        text:
          'Let us define the artistic masters of our land and pay homage to them by frequently hosting presentations of their artwork.',
      },
    ],
  },
  {
    id: 'MM4A',
    image: (props) => <img {...props} src={MM4A} alt="choose" />,
    bodyText:
      'The arts community is split. Those who benefit from your policies keep silent. Others who are neglected grow angry. Nonetheless, the opening of a new museum is welcomed by all. However, there is a need to establish policies that ensure such divisions never happen again.',
    choices: [
      {
        to: 'MM5A',
        text:
          'Implement rigid rules to prevent the proliferation of practices that defy your values.',
      },
    ],
  },
  {
    id: 'MM4B',
    bodyText:
      'Residencies start springing up. They attract a wide range of artists from all over the world. These artists come from cultures both similar to and different from ours.',
    choices: [
      {
        to: 'MM5A',
        text:
          'Implement stringent rules that artists must abide by in order to present their artwork. We cannot let artistic freedom offend public sensibilities.',
      },
      {
        to: 'MM5B',
        text:
          'Embrace freedom of expression and help to foster openness and tolerance of diversity amongst audiences.',
      },
    ],
  },
  {
    id: 'MM4C',
    bodyText:
      'Arts education takes off in schools. There are programmes that cater to a range of students, from primary to post-graduate levels. As people are increasingly exposed to art, they grow more receptive to new ideas and activities.',
    choices: [
      {
        to: 'MM5B',
        text:
          'Let us welcome artists from all around the world so they may contribute to the diversity of the ecosystem.',
      },
      {
        to: 'MM5C',
        text:
          'Funding should be diverted towards the production of more art events so people can continue to grow and satisfy their curiosity.',
      },
    ],
  },
  {
    id: 'MM4D',
    bodyText:
      'With the success of art-in-community events, people grow more open to new experiences and cultures. As audience members increase in number, the demand for art explodes.',
    choices: [
      {
        to: 'MM5C',
        text:
          'We fund more ground-up art initiatives and let the ecosystem take shape naturally.',
      },
      {
        to: 'MM5D',
        text:
          'Now would be an opportune moment to use our vast resources to develop activities that can shape the ecosystem and audiences for our own purposes.',
      },
    ],
  },
  {
    id: 'MM4E',
    bodyText:
      'After the first few years of excitement, people start to lose interest in the museum. Even though the architecture is grand, nobody is inspired by its stale content.',
    choices: [
      {
        to: 'MM5D',
        text:
          'Let us dispense information claiming how much people are enjoying the artistic and cultural products of our homeland.',
      },
    ],
  },
  {
    id: 'MM5A',
    bodyText:
      'An artwork that is on display at the museum as part of a prestigious exhibition has generated public ire. The culprits are pornographic magazines that serve as props in the installation. Although they are out of reach, the magazines are an affront to your conservative values. ',
    choices: [
      {
        to: 'MM6A',
        text:
          'Our shining institution is no place for such lewd material. The artwork must be removed.',
      },
    ],
  },
  {
    id: 'MM5B',
    image: (props) => <img {...props} src={MM5B} alt="choose" />,
    imageCaption: [
      'Ali Esmaeilipour, Immigrant No. 16, 2018, Acrylic, collage, gold & silver leaves on linen. Collection of Jo.',
    ],
    bodyText: [
      'The arts community becomes increasingly diverse. Artists from different backgrounds and places of birth begin to long for integration and recognition within the local community.',
      'Ali Esmaeilipour, for example, is not recognised as a local artist even though he has spent a long time here. Like other such artists, their works find little support within the institution.',
    ],
    choices: [
      {
        to: 'MM6A',
        text:
          'A distinction needs to be drawn between the locals and foreigners. Existing structures must be maintained and upheld.',
      },
      {
        to: 'MM6B',
        text:
          'Segregating artists by labels needs to be rethought. We should embrace evolving systems of classification and support.',
      },
    ],
  },
  {
    id: 'MM5C',
    image: (props) => <img {...props} src={MM5C} alt="choose" />,
    imageCaption: [
      'Felicia Low, Visioning the Arts, 2021, Mixed media installation. Collection of the artist.',
    ],
    bodyText:
      "At an independent art event called OH!, an academic and artist named Dr. Felicia Low presents a series of artwork that showcases her research and interactions with the community. Her artwork highlights that the artistic community is largely pessimistic about the future of the country's art scene.",
    choices: [
      {
        to: 'MM6B',
        text:
          'Recognise the importance of the information that you have been shown and work with artists to improve their situation.',
      },
      {
        to: 'MM6C',
        text:
          'Refute the relevance of this data. Her methods are questionable and the sample size is small. We have plenty of evidence that shows her conclusion is invalid.',
      },
    ],
  },
  {
    id: 'MM5D',
    image: (props) => <img {...props} src={MM5D} alt="choose" />,
    imageCaption: [
      'Martin Constable, Explosion of Heat in my Dark Siberia, 2015, Oil paint on MDF board. Collection of Jo.',
    ],
    bodyText:
      'The events that have proliferated start to take on an air of entertainment rather than artistry. The arts community loses hope of sustaining a vibrant arts ecosystem. At this time, a painting done by Martin Constable captures these sentiments.',
    choices: [
      {
        to: 'MM6C',
        text:
          'Let us organise parties to rejuvenate the arts precincts. The events will attract many and participants will have fun.',
      },
    ],
  },
  {
    id: 'MM6A',
    image: (props) => <img {...props} src={MM6A_JS6A} alt="choose" />,
    shareHeader: 'Mustapha Mond has died - Passion Made Impossible',
    shareBody:
      'My character made a series of decisions that ensued a cultural desert.%0A%0AWhich ending will be YOUR fate? Embark on new digital experiences by OH! Open House.',
    rightHeaderText: 'You Died',
    outcome: true,
    rightBodyText: [
      'The arts community is angered by your actions. Led by John the Savage, artists around the island rise in open rebellion against you. You are eventually killed in the resulting skirmishes with the artists.',
      'The city of culture you envisioned has failed to materialise.',
      'As the rebellion subsides and things quieten down, the artists return to their work and create many wonderful things. But nothing has changed. Most people still don’t understand nor care about what the artists are doing. ',
    ],
  },
  {
    id: 'MM6B',
    image: (props) => <img {...props} src={MM6B_JS6B} alt="choose" />,
    shareHeader: 'You joined forces with John Savage - Passion Made Impossible',
    shareBody:
      'My series of decisions created the ideal cultural climate.%0A%0AWhich ending will be YOUR fate? Embark on new digital experiences by OH! Open House.',
    rightHeaderText: 'In It Together',
    outcome: true,
    rightBodyText: [
      'Your wise choices have won over John the Savage. He recognises that the arts community can work together harmoniously with the state to realise the vision of a shining cultural metropolis.',
      'In the years to come, the arts centres will be filled with all manner of artwork that inspire the spirit and uplift the soul. All the people of the island work fervently together to build a utopian city.',
    ],
  },
  {
    id: 'MM6C',
    image: (props) => <img {...props} src={MM6C_JS6C} alt="choose" />,
    shareHeader: 'You killed John the Noble Savage - Passion Made Impossible',
    shareBody:
      'My character made a series of decisions that ensued artistic oasis.%0A%0AWhich ending will be YOUR fate? Embark on new digital experiences by OH! Open House.',
    rightHeaderText: 'You Have Killed John Savage',
    outcome: true,
    rightBodyText: [
      'The gradual downward spiral of the arts and cultural scene has caused many artists to lose hope and give up on their craft.',
      'In the years to come, your shining arts venues will be filled with mind-numbing entertainment.',
      'Depressed by the milieu, John the Noble Savage, one of the despondent artists, hanged himself.',
    ],
  },

  // John Savage side
  {
    id: 'JS1',
    bodyText:
      '"O brave new world! It is as if I have awoken from a dream. I have arrived at the edge of the city … oh, what wonderful people I shall meet. The verdant green here inspires me, but there yonder more people I shall seek.  Let me begin by:',
    choices: [
      { to: 'JS2A', text: 'Roaming around the outskirts.' },
      { to: 'JS2B', text: 'Heading towards the city centre.' },
    ],
  },
  {
    id: 'JS2A',
    image: (props) => <img {...props} src={JS2A} alt="choose" />,
    imageCaption: [
      'Tang Da Wu, Untitled, 2013, Ink on paper. Collection of Jo.',
    ],
    bodyText:
      'Amidst the outskirts of the city, you encounter a man named Tang Da Wu, who has established a troupe of artists in a village. He invites you to contemplate with him a painting he recently completed. The work is big, dark, and swirling; you try to make something of it:',
    choices: [
      {
        to: 'JS3A',
        text:
          '“Nothing will come of nothing. The darkness present in the work is all consuming.”',
      },
      {
        to: 'JS3B',
        text:
          '“All that glitters is not gold. The scale is grand and majesty impressive to behold!”',
      },
    ],
  },
  {
    id: 'JS2B',
    image: (props) => <img {...props} src={MM3A_JS2B} alt="choose" />,
    bodyText:
      'You wander into a shopping mall and encounter a co-founder of an arts venue formerly located on the mall’s 4th-floor corridor. A recent media-incited controversy forced the mall to evict them, and you catch them packing their belongings. As you gaze upon their countenance, you remark:',
    choices: [
      {
        to: 'JS3C',
        text:
          '“The better part of valour is discretion. Take this in your stride and your work shall come into recognition.”',
      },
      {
        to: 'JS3D',
        text:
          '“Fair is foul, and foul is fair! What wretched terms you have received, they seek to strip you even of your hair!”',
      },
    ],
  },
  {
    id: 'JS3A',
    bodyText:
      'Stepping out of the village into the woods, you stumble upon a man taking photographs of birds. The man tells you of the birds, soon to lose their homes from the deforestation caused by property developers. All that will remain of life here are the photographs he is taking. Observing your surroundings, you say to him:',
    choices: [
      {
        to: 'JS4A',
        text:
          '“Now is the winter of our discontent. When all is lost, it shall be too late for these men to repent.”',
      },
      {
        to: 'JS4B',
        text:
          '“We know what we are, but know not what we may be. Perhaps you may strike a chord in them with your photography?”',
      },
    ],
  },
  {
    id: 'JS3B',
    bodyText:
      'A man whose body is painted yellow is walking down the street in his undergarments. He is, at once, the blazing sun, an abundant harvest, the  colour of pornography, and a shower of gold. He is upset with how he is treated because of his skin colour, so he has made it a statement. You walk alongside him quietly, and say to him:',
    choices: [
      {
        to: 'JS4B',
        text:
          '“In time we hate that which we often fear. Such has been the case for many a long year.”',
      },
      {
        to: 'JS4C',
        text:
          '“This above all: to thine own self be true. The righting of wrongs should not be taboo.”',
      },
    ],
  },
  {
    id: 'JS3C',
    bodyText:
      'Strolling down the streets, you spot a curious sight: a group of women with a shoe in their mouth are walking backwards by navigating the path behind them with a mirror. They are protesting their termination from employment. Running up alongside them, you say:',
    choices: [
      {
        to: 'JS4C',
        text:
          '"Sigh no more, ladies, sigh no more, men were deceivers ever. You are not bound by duty to give them ocular pleasure.”',
      },
      {
        to: 'JS4D',
        text:
          '“The lady doth protest too much, methinks. It is far easier to enchant your employers with a wink.”',
      },
    ],
  },
  {
    id: 'JS3D',
    image: (props) => <img {...props} src={JS3D} alt="choose" />,
    bodyText:
      'You spot an artist painting on the street. Following them, you notice them stopping at every crossing to label each road as their grandfather’s. "Is this true?" you ask. They answer, "My grandfather is everyone’s grandfather and every road we cross is his." You reply:',
    choices: [
      {
        to: 'JS4D',
        text:
          '"It is a wise father that knows his own child, but perhaps your own actions have been a little wild."',
      },
      {
        to: 'JS4E',
        text:
          '"Uneasy lies the head that wears a crown. Tyrants must be called out and brought down!"',
      },
    ],
  },
  {
    id: 'JS4A',
    bodyText:
      'Walking out of the forest onto a sandy beach, you encounter a man sawing a boat in half. Perplexed by his actions, you ask him what he is doing. He tells you that he built the boat, intending to exhibit it in a museum. But it could not be manoeuvred into the museum, so he has no choice but to saw it in half.  Stunned, you say to him:',
    choices: [
      {
        to: 'JS5A',
        text:
          '“Full fathom five thy father lies; of his bones are coral made. This madness is indeed reflective of the times ahead.”',
      },
    ],
  },
  {
    id: 'JS4B',
    image: (props) => <img {...props} src={JS4B} alt="choose" />,
    imageCaption: [
      'Nino Sarabutra, Your Singapore, 2021, Print on canvas with ceramic skulls on thumbtack. Collection of the artist.',
    ],
    bodyText:
      'At a busy intersection, you encounter Nino Sarabutra, an artist from another land. She asks the many passers-by if they have done anything good for their country, for others, or for their families. For each answer, she affixes a corresponding pin on a map of the island. You remark:',
    choices: [
      {
        to: 'JS5A',
        text:
          '"Small things make base men proud. I wonder if their actions truly speak as loud."',
      },
      {
        to: 'JS5B',
        text:
          '"An honest tale speeds best being plainly told. Where good people work together, the island turns to gold."',
      },
    ],
  },
  {
    id: 'JS4C',
    image: (props) => <img {...props} src={JS4C} alt="choose" />,
    imageCaption: [
      'Wong Lip Chin, 大公街 (Jalan Tuakong), 2021, incense-led artist experience. Collection of the artist.',
    ],
    bodyText:
      'You find yourself at a gathering of artists. One, named Lip Chin, lights some incense and a lively conversation ensues. The artists discuss their hopes, dreams, aspirations, and paths to success. When the incense burns out, Lip Chin exclaims that the gathering is over—but the art continues. You wonder aloud:',
    choices: [
      {
        to: 'JS5B',
        text:
          '“We know what we are, but know not what we may be. Even with dreams that drive us on, life makes no guarantees.”',
      },
      {
        to: 'JS5C',
        text:
          '“With mirth and laughter let old wrinkles come. Only time will tell what we may become.”',
      },
    ],
  },
  {
    id: 'JS4D',
    image: (props) => <img {...props} src={JS4D} alt="choose" />,
    imageCaption: [
      'Nadiah Bamadhaj, Unaltered Country II, 2014, Charcoal on paper collage. Collection of Jo.',
    ],
    bodyText:
      'Within a temple burnt hollow to its shell, Nadiah Bamadhaj completes a charcoal-on-paper collage of an altar table; her artwork blends Chinese and Javanese motifs. As she laments how intercultural tensions where she is based have caused the destruction of this historic site, you say to her:',
    choices: [
      {
        to: 'JS5C',
        text:
          '"Men’s evil manners live in brass; their virtues we write in water. While the originals may be gone, your work enshrines the matter."',
      },
      {
        to: 'JS5D',
        text:
          '"My drops of tears I’ll turn to sparks of fire. When the culprits are found, the consequences shall be dire!"',
      },
    ],
  },
  {
    id: 'JS4E',
    image: (props) => <img {...props} src={JS4E} alt="choose" />,
    bodyText:
      'An artist named Andy has doodled a menagerie of curious pictures and is displaying them in an underpass. Agitated by a drawing of a man riding a rooster, a group of angry pedestrians clamber up to him and yell for its removal. You swat them back and shout:',
    choices: [
      {
        to: 'JS5D',
        text:
          '“Lord, what fools these mortals be! Ignore them you must, and we shall carry on with dignity!”',
      },
    ],
  },
  {
    id: 'JS5A',
    bodyText:
      'Walking in from the coast, you arrive at a small community centre. There, you meet a theatre director. He tells you that his theatre company, The Obligatory Podium, has put up hundreds of plays on this stage for over 20 years. But they will soon lose their home amidst the changing fortunes of the times. Your slump your shoulders, bow your head, and tell him:',
    choices: [
      {
        to: 'JS6A',
        text:
          '"All the world’s a stage, and all the men and women merely players. What else can we do if they only seek to slay us?"',
      },
    ],
  },
  {
    id: 'JS5B',
    image: (props) => <img {...props} src={JS5B} alt="choose" />,
    bodyText:
      "Wandering into the city’s finest museum, you encounter two young artists who are siblings to each other. Sad and dejected, they are in the midst of removing their paintings about growing up together, at the museum's demands. You look at the heartbroken children and say to them:",
    choices: [
      {
        to: 'JS6A',
        text:
          '“My tongue will tell the anger of my heart, or else my heart concealing it will break. Yet nothing more can be done here, but to tend to our heartaches.”',
      },
      {
        to: 'JS6B',
        text:
          '“There is nothing either good or bad, but thinking makes it so. These passing experiences, will only help you grow.”',
      },
    ],
  },
  {
    id: 'JS5C',
    bodyText:
      'You arrive at one of the most prestigious art events in the land and discover a crowd gone amok. An artist named Gunasekaran has just slashed himself to protest the egregious censorship of his artwork. You shout to the artist:',
    choices: [
      {
        to: 'JS6B',
        text:
          '“This is very midsummer madness, is there no better way to seek redress?”',
      },
      {
        to: 'JS6C',
        text:
          '“Cry ‘Havoc!’ and let slip the dogs of war! Why tolerate this system anymore!”',
      },
    ],
  },
  {
    id: 'JS5D',
    image: (props) => <img {...props} src={JS5D} alt="choose" />,
    bodyText:
      'Several youths sporting mohawks have gathered outside an old electrical substation. Amidst music blaring from their stereos, they speculate on the rumours that they will soon lose their haunt to property redevelopment. They are visibly perturbed and at a loss at what to do or think. You rile them up by saying:',
    choices: [
      {
        to: 'JS6C',
        text:
          '“Off with his head! Why dwell in your malcontent when we can smite him dead!”',
      },
    ],
  },
  {
    id: 'JS6A',
    image: (props) => <img {...props} src={MM6A_JS6A} alt="choose" />,
    outcome: true,
    shareHeader: 'John the Noble Savage has died - Passion Made Impossible',
    shareBody:
      'My character made a series of decisions that ensued a cultural desert.%0A%0AWhich ending will be YOUR fate? Embark on new digital experiences by OH! Open House.',
    rightHeaderText: 'You Died',
    rightBodyText: [
      "Your words have deepened the artists' pessimism about the cultural conditions in the country. Over time, these artists lose confidence in creating new artwork. Art disappears from art venues and are replaced by insipid entertainment.",
      'Growing increasingly despondent, you decide to hang yourself as the situation is too much to bear.',
    ],
  },
  {
    id: 'JS6B',
    outcome: true,
    image: (props) => <img {...props} src={MM6B_JS6B} alt="choose" />,
    shareHeader:
      'You joined forces with Mustapha Mond - Passion Made Impossible',
    shareBody:
      'My series of decisions created the ideal cultural climate.%0A%0AWhich ending will be YOUR fate? Embark on new digital experiences by OH! Open House.',
    rightHeaderText: 'In It Together',
    rightBodyText: [
      'Your words have invigorated the community and have drawn the attention of Mustapha Mond. He recognises that the arts community can work together harmoniously with the state to realise the vision of a shining cultural metropolis.',
      'In the years to come, the arts centres will be filled with all manner of artwork that inspire the spirit and uplift the soul. All the people of the island work fervently together to build a utopian city.',
    ],
  },
  {
    id: 'JS6C',
    outcome: true,
    image: (props) => <img {...props} src={MM6C_JS6C} alt="choose" />,
    shareHeader: 'You killed Mustapha Mond - Passion Made Impossible',
    shareBody:
      'My character made a series of decisions that ensued artistic oasis.%0A%0AWhich ending will be YOUR fate? Embark on new digital experiences by OH! Open House.',
    rightHeaderText: 'You Have Killed Mustapha Mond',
    rightBodyText: [
      'Incensed by the conditions within the World State and incited by you, the arts community engages in open rebellion against World Controller Mustapha Mond. Amidst one of the many skirmishes across the city, you swing a hammer down upon his head and strike him dead.',
      'In the years to come, the artists shape the world according to their every desire.',
      'But there is no venue to display their art, nor any person who wants to look at or understand it.',
    ],
  },
]

export default tree
