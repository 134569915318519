import image0 from '../assets/img/jl/0.jpg'
import image1 from '../assets/img/jl/1.jpg'
import image60A from '../assets/img/jl/60A.jpg'
import image60C from '../assets/img/jl/60C.jpg'
import image60E from '../assets/img/jl/60E.jpg'
import image60J from '../assets/img/jl/60J.jpg'
import image60L from '../assets/img/jl/60L.jpg'
import image20A from '../assets/img/jl/20A.jpg'
import image20B from '../assets/img/jl/20B.jpg'
import image20C from '../assets/img/jl/20C.jpg'
import image20E from '../assets/img/jl/20E.jpg'
import zero from '../assets/img/jl/60B_60F_60H_60K_60P_60Q_20F_20G_20K.jpg'
import fifty from '../assets/img/jl/20I_20J.jpg'
import hundred from '../assets/img/jl/60M_60N.jpg'

const tree = [
  {
    id: 'start',
    image: (props) => <img {...props} src={image0} alt="" />,
    rightHeaderText: 'To Art Or Not To Art?',
    rightBodyText: [
      'You begin this game as a 16-year-old Singaporean who aspires to be an artist. As you progress through life, you will encounter events that force you to make decisions that will irrevocably shape your future. Your aim is to survive as an artist for as long as possible, but also to find your place in history.',
      'The characters and events in this game are purely fictional; any similarities to people living or dead are purely coincidental. Any opinion expressed here is satirical and does not reflect the views of OH! Open House.',
    ],
    next: '1',
    choices: [{ to: '1', text: 'Start Experience' }],
  },
  {
    id: '1',
    image: (props) => <img {...props} src={image1} alt="" />,
    headerText: 'Which Era Would You Like To Live In?',
    bodyText: [
      "The 1960s is a decade of watershed events for Singapore. As a newly independent nation, we are struggling to define our identity and secure a rice bowl. Issues unrelated to economic survival, such as the role of art in this fledgling nation, are luxuries we cannot afford. 'Culture', according to the newly formed Ministry of Culture, means national identity and the Singapore Pledge, not the creative arts. In this cultural desert, the prospects of becoming a professional artist are bleak.",
      "During the 2000s, Singapore is a progressive nation with aspirations of being a global arts hub, as laid out in the Renaissance City Plan. Cultural infrastructure for the arts is booming: dedicated art schools, venues, museums, and galleries are springing up rapidly. But under the surface, things are not as rosy as they seem. People say that Singapore's hardware for the arts exist, but not the 'heartware'. The prospects of being a professional career artist are not as bleak as that in the 1960s, but society still frowns on people who do not take up a stable 9-to-6 corporate job.",
    ],
    choices: [
      { to: '60A', text: '1960s' },
      { to: '20A', text: '2000s' },
    ],
  },

  // 1960s
  {
    id: '60A',
    image: (props) => <img {...props} src={image60A} alt="" />,
    bodyText: 'What is your gender?',
    choices: [
      { to: '60B', text: 'Female' },
      { to: '60C', text: 'Male' },
    ],
  },
  {
    id: '60B',
    outcome: true,
    image: (props) => <img {...props} src={zero} alt="" />,
    shareHeader: 'You failed - Judging Labels',
    shareBody:
      'You’ve been written out of local art history.%0A%0AWhich ending will be YOUR fate? Embark on new digital experiences by OH! Open House.',
    rightBodyText: [
      'As a woman, you are viewed as a mere hobbyist instead of a professional artist, even if you are exceptionally talented. Society at this time deems that your domain is limited to the domestic; your family must come before your career!',
      "You've been written out of history.",
    ],
  },
  {
    id: '60C',
    image: (props) => <img {...props} src={image60C} alt="" />,
    bodyText: [
      'As the young, newly independent nation searches for its identity, the lawmakers introduce many new policies to instil pride and a sense of community. One of these measures is the decision to label the population based on their racial background.',
      'Are you a part of the:',
    ],
    choices: [
      { to: '60D', text: 'Majority' },
      { to: '60O', text: 'Minority' },
    ],
  },
  {
    id: '60D',
    bodyText: [
      'Congratulations on winning the genetic and social lottery! As part of the majority group in young Singapore, you are afforded many opportunities and connections because of the colour of your skin.',
      'Pondering on what you should do to develop your arts career, you decide to:',
    ],
    choices: [
      { to: '60J', text: 'Go overseas for formal art education.' },
      { to: '60E', text: 'Stay in Singapore.' },
    ],
  },
  {
    id: '60E',
    image: (props) => <img {...props} src={image60E} alt="" />,
    bodyText: [
      "Your family is uanble to afford an overseas education for you, so you remain in Singapore. Here, you are torn between the cheaper option of attending public school and practising your art part-time, or spending all your savings to attend the Nanyang Academy of Fine Arts (NAFA), Singapore's only formal art school staffed by respected artists.",
    ],
    choices: [
      { to: '60F', text: 'Attend public school.' },
      { to: '60G', text: 'Attend NAFA.' },
    ],
  },
  {
    id: '60F',
    outcome: true,
    image: (props) => <img {...props} src={zero} alt="" />,
    shareHeader: 'You failed - Judging Labels',
    shareBody:
      'You’ve been written out of local art history.%0A%0AWhich ending will be YOUR fate? Embark on new digital experiences by OH! Open House.',
    rightBodyText: [
      "Years of juggling public school and working a part-time job mean that you only have time to practise your art a few hours a week. Seen as a 'Sunday painter' by many, you lead a life of mediocrity and resign yourself to obscurity.",
      "You've been written out of history.",
    ],
  },
  {
    id: '60G',
    bodyText: [
      "At NAFA, you receive tutelage from artists like Liu Kang and Chen Wen Hsi, who will later be known by history as the 'first-generation masters'. They inspire you with both 'Western' and 'Eastern' art.",
      'After graduating, the world is your oyster. Even though you are a natural introvert and do your best work when alone, you can see the advantages of navigating the complex art world with a friend or two.',
    ],
    choices: [
      { to: '60J', text: 'You decide to join a society.' },
      { to: '60H', text: 'You decide to strike out alone.' },
    ],
  },
  {
    id: '60H',
    outcome: true,
    image: (props) => <img {...props} src={zero} alt="" />,
    shareHeader: 'You failed - Judging Labels',
    shareBody:
      'You’ve been written out of local art history.%0A%0AWhich ending will be YOUR fate? Embark on new digital experiences by OH! Open House.',
    rightBodyText: [
      'As a solo artist in the art world, you have independence, but find it hard to get noticed by the community. After waiting many years in vain for a curator to discover you, you decide to give up on creating art.',
      'Instead, with your educational background in NAFA, you devote your energies to becoming a teacher. You end up as an inspirational teacher who guides the next generation of artists in the country.',
    ],
  },
  {
    id: '60J',
    image: (props) => <img {...props} src={image60J} alt="" />,
    bodyText: [
      'Supported by the many opportunities you have because of your connections and background, you take a leap of faith and pursue a career as a full-time artist.',
      "You begin making art in the backdrop of the 'Asian Values Debate', a tussle between upholding traditional values and accepting modern ideas. The genre and techniques you work with serve as a proxy for the side you pick in this debate.",
      'After considering carefully, you decide to embrace:',
    ],
    choices: [
      { to: '60K', text: 'Traditional art.' },
      { to: '60L', text: 'Modern art.' },
    ],
  },
  {
    id: '60K',
    outcome: true,
    image: (props) => <img {...props} src={zero} alt="" />,
    shareHeader: 'You failed - Judging Labels',
    shareBody:
      'You’ve been written out of local art history.%0A%0AWhich ending will be YOUR fate? Embark on new digital experiences by OH! Open House.',
    rightBodyText: [
      'You believe that it is important to honour your ancestry and follow the conventions of the time. Traditional styles like ink and calligraphy are the focus of your practice.',
      'Happily, commercial success is yours to savour because the public enjoys familiar art that they understand. However, you are not the only artist to realise this; there is an oversaturation of artists working in this genre.',
      'Decades later, art history has all but forgotten you, despite the popularity of your artwork amongst the public during your time.',
    ],
  },
  {
    id: '60L',
    image: (props) => <img {...props} src={image60L} alt="" />,
    imageCaption: [
      '[Left] Goh Beng Kwan, Balloon Party, 2006, Mixed media on air dried textural stained base sheet. DUO collection',
      '[Right] Aisha Rosli, Be Careful With a Fool, 2021, Mixed media on canvas.',
    ],
    bodyText: [
      'Bored by societal conventions and the strict rules of traditional painting, you seek new ways of expressing yourself. You expose yourself to new techniques and styles, while working hard to harmonise them with your identity as a Singaporean.',
      'After a period of experimentation, you narrow down your focus between two styles:',
    ],
    choices: [
      { to: '60M', text: 'Abstract art.' },
      { to: '60N', text: 'Figurative art.' },
    ],
  },
  {
    id: '60M',
    outcome: true,
    image: (props) => <img {...props} src={hundred} alt="" />,
    shareHeader: 'You aced it - Judging Labels',
    shareBody:
      'Congrats! Labels work in your favour to keep you written in history.%0A%0AWhich ending will be YOUR fate? Embark on new digital experiences by OH! Open House.',
    rightBodyText: [
      "Inspired by the free and energetic works of Wassily Kadinsky, Jackson Pollock, and William de Kooning, you devote yourself to non-representational, abstract art. But you are way ahead of your time; people don't understand your art. Still, you persist in creating your works.",
      "Your perseverence will be rewarded decades later, when the art community sees your work in a new light and labels you as one of the 'second-generation' artists of Singapore.",
      'You are one of the lucky few. Many artists whom you know have fallen by the wayside and are forgotten, due to the vissicitudes of fate.',
    ],
  },
  {
    id: '60N',
    outcome: true,
    image: (props) => <img {...props} src={hundred} alt="" />,
    shareHeader: 'You aced it - Judging Labels',
    shareBody:
      'Congrats! Labels work in your favour to keep you written in history.%0A%0AWhich ending will be YOUR fate? Embark on new digital experiences by OH! Open House.',
    rightBodyText: [
      "Even though the Singapore River has been portrayed in art ad infinitum, you believe that there must be a better way of capturing its essence. Drawing on your practice in figurative art, you produce many artwork of Singapore's notable landscapes as it modernises through the decades.",
      "Your artwork strikes a chord with locals and finds favour with institutions and collectors. Most of your paintings are displayed in museums and even make their way to textbooks. Future generations, too, study your paintings as important chronicles of Singapore's history.",
      "Congratulations, you've survived local art history!",
    ],
  },
  {
    id: '60O',
    bodyText: [
      'People seem unable to see beyond your skin colour. When they learn that you are an artist, they expect you to only produce artwork related to your traditions and culture. Pondering this myopic perspective, you decide to:',
    ],
    choices: [
      { to: '60P', text: 'Pay heed to these stereotypes.' },
      { to: '60Q', text: 'Ignore these shallow expectations.' },
    ],
  },
  {
    id: '60P',
    outcome: true,
    image: (props) => <img {...props} src={zero} alt="" />,
    shareHeader: 'You failed - Judging Labels',
    shareBody:
      'You’ve been written out of local art history.%0A%0AWhich ending will be YOUR fate? Embark on new digital experiences by OH! Open House.',
    rightBodyText: [
      "Listening to the sentiment on the ground, you decide to integrate your culture with your art. Even though creating 'ethnic art' makes your artwork hover at the cusp of art and craft, you perservere and pour yourself into your work.",
      'Still, your art struggles to gain recognition. Frustrated and dejected, your passion for creating artwork wanes, and you give up on being a full-time artist.',
      "You will only be noticed by curators and art historians years later, when 'multiculturalism' is not mere marketing buzz but something society really understands. Unfortunately, this happens too late, way past your lifetime.",
    ],
  },
  {
    id: '60Q',
    outcome: true,
    image: (props) => <img {...props} src={zero} alt="" />,
    shareHeader: 'You failed - Judging Labels',
    shareBody:
      'You’ve been written out of local art history.%0A%0AWhich ending will be YOUR fate? Embark on new digital experiences by OH! Open House.',
    rightBodyText: [
      "Defiant, you choose not to be defined by your ethnicity or stereotypes. You produce artwork that feels authentic to you. However, society still looks at you as an 'ethnic artist', and does not pay you attention when you do not give them what they expect. You resign yourself to circumstances and give up hope of being an artist.",
      "However, the importance of art is a belief that stays with you. If you can't pursue art, maybe someone else can. You decide to become an art teacher, imparting your knowledge and skills to the younger generation in local schools. It is a noble pursuit, but there is no space for art history for you.",
    ],
  },

  // 2000s
  {
    id: '20A',
    image: (props) => <img {...props} src={image20A} alt="" />,
    bodyText: [
      'As a teenager of the 2000s, you are fortunate to have the option to enrol in a variety of art schools. There, you can learn the fundaments and history of your practice from different artists.',
      'On the other hand, you wonder if eschewing formal art school and exploring the art world yourself will give you a different and more refreshing perspective on art?',
    ],
    choices: [
      { to: '20B', text: 'Enrol in a formal art school.' },
      { to: '20K', text: 'Refuse a formal art education.' },
    ],
  },
  {
    id: '20B',
    image: (props) => <img {...props} src={image20B} alt="" />,
    bodyText: [
      'Learning about the field from trained practitioners is important, so you decide to enrol in art school. As part of your academic progress, you are required to specialise in a genre and medium of art.',
      'After spending sleepless nights agonising over the decision, you have finally made up your mind:',
    ],
    choices: [
      { to: '20C', text: 'Specialise in painting.' },
      { to: '20D', text: 'Specialise in multi-disciplinary, conceptual art.' },
    ],
  },
  {
    id: '20C',
    image: (props) => <img {...props} src={image20C} alt="" />,
    bodyText: [
      'At first, you are worried that you have specialised in a dead medium. Every year, there is someone decrying painting as passé.',
      "But you quickly realise that it's still a burgeoning genre, bolstered by the UOB Painting of the Year Awards and high demand among collectors. In fact, every artist who is not a painter secretly envies the support painters receive.",
      'With so many opportunities at hand, you can decide what type of painter you want to be:',
    ],
    choices: [
      { to: '20F', text: 'A commercial painter.' },
      { to: '20G', text: 'A commissioned painter.' },
    ],
  },
  {
    id: '20D',
    bodyText: [
      'Wanting to push boundaries and introduce new ideas of what art can be, you become a multi-disciplinary artist. To make your art culturally relevant, you draw inspiration from events happening around you.',
      'You decide to infuse your art with:',
    ],
    choices: [
      { to: '20E', text: 'Your uncensored opinions on sociopolitical issues.' },
      { to: '20H', text: 'Your moderated opinions on sociopolitical issues.' },
    ],
  },
  {
    id: '20E',
    image: (props) => <img {...props} src={image20E} alt="" />,
    outcome: true,
    rightBodyText: [
      'Incensed up by the injustices you see, you embed messages on race, religion, and politics in your art. As a result, institutions are now wary of associating themselves with a firebrand like you.',
      'While some independent spaces and the local arts community try to support you initially, the difficulty of negotiating with the state wears away at your tenacity. Your frustrations engulf you and you lose your drive.',
      'Your career as an artist ends.',
    ],
  },
  {
    id: '20F',
    outcome: true,
    image: (props) => <img {...props} src={zero} alt="" />,
    shareHeader: 'You failed - Judging Labels',
    shareBody:
      'You’ve been written out of local art history.%0A%0AWhich ending will be YOUR fate? Embark on new digital experiences by OH! Open House.',
    rightBodyText: [
      'As a commercial painter, you are greeted with the prospect of a stable income from the constant exhibitions of your artwork in galleries both local and abroad.',
      'But your glory is short-lived. After continuously reproducing artwork that is the most commercially viable, you grow jaded of your work. Collectors, too, lose interest in seeing the same style repeated, and move on to the next trend in the art world.',
      "You've been written out of history.",
    ],
  },
  {
    id: '20G',
    outcome: true,
    image: (props) => <img {...props} src={zero} alt="" />,
    shareHeader: 'You failed - Judging Labels',
    shareBody:
      'You’ve been written out of local art history.%0A%0AWhich ending will be YOUR fate? Embark on new digital experiences by OH! Open House.',
    rightBodyText: [
      "As a commisioned artist, people hire you to draw portraits of their family, their dogs, their birds ... The work is not why you studied art, but you're not complaining about the easy money.",
      'After a few years of doing this, however, you grow weary. You find no meaning in your work and your income has stagnated. As societal and familial pressures mount, you decide to give up being an artist and find a more typical career.',
    ],
  },
  {
    id: '20H',
    bodyText: [
      "You grow proficient in the balancing act between staying 'woke' and remaining palatable to the authorities. Both the arts community and the authorities adore you, opening up many opportunities for you.",
      'Surveying the expansive range of options you are entitled to, you decide your next step will be to:',
    ],
    choices: [
      {
        to: '20I',
        text:
          "Accept the industry stalwarts' offer of participating in the next Singapore Biennale. ",
      },
      { to: '20J', text: 'Start your own arts space.' },
    ],
  },
  {
    id: '20I',
    outcome: true,
    image: (props) => <img {...props} src={fifty} alt="" />,
    shareHeader: 'You barely passed - Judging Labels',
    shareBody:
      'History will remember you for everything else but your art.%0A%0AWhich ending will be YOUR fate? Embark on new digital experiences by OH! Open House.',
    rightBodyText: [
      'You show your work at the Singapore Biennale to rousing success. After this stint, you gain international and institutional recognition.',
      "However, with the lack of a long-term plan and a permanent studio, you work from project to project, live from grant to grant. Eventually, the vagaries of this life wear you down. It is simply not sustainable. Every day, especially on those when you feel particularly exhausted, you wonder if you should've taken on a typical 9-to-6 corporate job, and regret the life you currently lead.",
      'Perhaps being an artist was not the best choice after all?',
    ],
  },
  {
    id: '20J',
    outcome: true,
    image: (props) => <img {...props} src={fifty} alt="" />,
    shareHeader: 'You barely passed - Judging Labels',
    shareBody:
      'History will remember you for everything else but your art.%0A%0AWhich ending will be YOUR fate? Embark on new digital experiences by OH! Open House.',
    rightBodyText: [
      'With the goodwill you have earned among the community, you start your own art studio.',
      "As the years go by, your artwork and studio become more renowned. But you don't forget that others who paved the way before you, so you resolve to give back to the community. You provide mentorship and support for young artists, and give them opportunities to show their art. You are labelled as the 'Artist-curator'.",
      "The arts community, grateful for your generosity, remembers you as an important figure in the nation's art history.",
    ],
  },
  {
    id: '20K',
    outcome: true,
    image: (props) => <img {...props} src={zero} alt="" />,
    shareHeader: 'You failed - Judging Labels',
    shareBody:
      'You’ve been written out of local art history.%0A%0AWhich ending will be YOUR fate? Embark on new digital experiences by OH! Open House.',
    rightBodyText: [
      'Individuality and experimentation cannot be overstated, so you strike it out on your own. You develop a practice that attracts some attention for its quirkiness and non-conformity to typical artwork. However, because your name lacks currency in the arts circles, you are unable to achieve true commercial or critical success.',
      'Undaunted, you juggle multiple jobs to fund your practice and continue creating art. But as the years go by, your commitments multiply; life takes over. You have less and less time for your own art. One day, you realise that you have not created anything in years.',
      "In the end, you are not 'starving', but neither are you an 'artist'. Your only response is to shrug in indifference.",
    ],
  },
]

export default tree
